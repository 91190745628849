import React, { useState, useRef, useEffect } from 'react';

const Field = ({name, label, type = 'text', maxLength, initialValue, placeholder, autoComplete, autoCapitalize, disabled, autoFocus, onValidate, onData, inputMode}) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false)
  const [error, setError] = useState(false)
  const tainted = useRef(false)
  const input = useRef()
  const value = useRef(initialValue || '')
  const changeTimer = useRef(null)

  const handleChange = () => {
    if (changeTimer.current) {
      clearTimeout(changeTimer.current)
      changeTimer.current = null
    }
    const data = input.current.value
    if (onValidate) {
      var error = onValidate(data)
      if (tainted.current) setError(error)
      value.current = data
      if (onData) onData(name, value.current, !error)
    } else {
      value.current = data
      if (onData) onData(name, value.current, true)
    }
  }

  const onChange = () => {
    if (changeTimer.current) clearTimeout(changeTimer.current)
    changeTimer.current = setTimeout(handleChange, 1500)
    if (tainted.current) setError(false)
    tainted.current = true
  }
  
  useEffect(() => {
    if (value.current && input.current.value !== value.current) input.current.value = value.current
    if (onData) onData(name, value.current, !onValidate(value.current))
  }, [onData, name, value, onValidate])

  const inputType = (type) => {
    switch (type) {
      case 'password':
        return (
          <div className='field-icon'>
            <input
              name={name}
              ref={input}
              type={isPasswordVisible ? 'text' : 'password'}
              maxLength={maxLength}
              autoComplete={autoComplete}
              autoCapitalize={autoCapitalize}
              placeholder={placeholder}
              disabled={disabled}
              onChange={onChange} 
              onBlur={handleChange}
              inputMode={inputMode}/>
            <span className={ (isPasswordVisible ? 'icon-eye-off' : 'icon-eye') + ' clickable' } onClick={() => { setPasswordVisible(!isPasswordVisible) }}></span>
          </div>)
      default:
        return <input
          name={name}
          ref={input}
          type={type}
          maxLength={maxLength}
          autoComplete={autoComplete} 
          autoCapitalize={autoCapitalize}
          autoFocus={autoFocus}
          placeholder={placeholder} 
          disabled={disabled}
          onChange={onChange} 
          onBlur={handleChange}
          inputMode={inputMode} />
    }
  }

  return (
    <div className='field'>
          <label>{label}</label>
          {inputType(type)}
          { error &&
            <span className='error right'>{error}</span>
          }          
    </div>
  )
}

export default Field