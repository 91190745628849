

class Storage {
  constructor() {
    this.prefix = 'vct_'
  }

  set(key, value) {
    localStorage.setItem(this.prefix + key, value)
  }

  get(key) {
    return localStorage.getItem(this.prefix + key)
  }

  delete(key) {
    localStorage.removeItem(this.prefix + key)
  }

  setCredentials(credentials) {
    this.set('credentials', JSON.stringify(credentials))
  }

  getCredentials() {
    var credentials = this.get('credentials')
    return credentials ? JSON.parse(credentials) : {};
  }

  setProfile(profile) {
    this.set('profile', JSON.stringify(profile))
  }

  getProfile() {
    var profile = this.get('profile')
    return profile ? JSON.parse(profile) : {};    
  }

  setCustomization(custom) {
    this.set('app_customization', JSON.parse(JSON.stringify(custom)))
  }

  getCustomization() {
    var custom = this.get('app_customization')
    return custom ? JSON.parse(custom) : "";    
  }
}

export default new Storage()