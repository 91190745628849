import React, { useEffect, useState, useCallback } from 'react'
import { withRouter, Link } from 'react-router-dom'
import Storage from '../helpers/Storage'
import Modal from '../components/Modal'
import Checkbox from '../components/Checkbox';
import Request from '../helpers/Request'
import LogoTitleImage from '../images/logo-title.png'
import '../css/Splash.css'

const TERMS_VERSION = '20-sep-2019'
const VERSION = '0.21'

const SplashScreen = withRouter(({ history }) => {
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [checked, setChecked] = useState(false)
  const [customization, setCustomization] = useState(false)
  const autovend = (Storage.get("autovend") === 'true');

  useEffect(() => {
    //Default falues
    Storage.set("subdomain", "test1")
    Storage.set("purchase_enabled", true)
    let saved_config = Storage.getCustomization()
    let location_href = window.location.href
    location_href = location_href.split("//");
    location_href = location_href[1].split(".")
    const company = location_href[0]
    if(saved_config === "") {
      Request.get('/get_customization/'+company, null).then((data) => {
        Storage.setCustomization(data['app_customization'])
        setCustomization(data['app_customization'])
        Storage.set("subdomain", company)
        Storage.set("purchase_enabled", JSON.parse(data['app_customization'])['pwa_purchase_enabled'])
      }).catch((error) => {
        //TODO: error
      })
    } else {
      setCustomization(saved_config)
      Storage.set("subdomain", company)
      Storage.set("purchase_enabled", saved_config['pwa_purchase_enabled'])
    }
  }, [])

  const redirect = useCallback(() => {
    var credentials = Storage.getCredentials()
     if (credentials.access_token) {
      history.push('/machines')
    } else {
      const defaultMachine = Storage.get('default_machine')
      if(defaultMachine !== null) {
        history.push('/quick-login')
      } else {
        history.push('/login')
      }
    }
  }, [history])

  const acceptTerms = () => {
    Storage.set('accepted_terms', TERMS_VERSION)
    redirect()
  }

  const timeout = useCallback(() => {
    setAcceptedTerms(Storage.get('accepted_terms') || '0.0')
    if (acceptedTerms === TERMS_VERSION) {
      redirect()
    }
  }, [acceptedTerms, redirect])

  useEffect(() => {
    setTimeout(timeout, 1000)
  }, [redirect, timeout])


  return (
    <div>
      <div className='splash-screen'>
        { typeof customization['pwa_logo'] !== 'undefined' &&
          <img src={customization['pwa_logo']} alt='' />
        }
        
        { typeof customization['pwa_logo'] === 'undefined' &&
          <img src={LogoTitleImage} alt='' />
        }
        <h2>{typeof customization['pwa_title'] !== 'undefined' ? customization['pwa_title'] : ''}</h2>
        <div className="splash-version">v{VERSION}</div>
      </div>
      { acceptedTerms && acceptedTerms !== TERMS_VERSION &&
        <Modal>
          <div className="center terms-conditions">
            <h1>{typeof customization['pwa_title'] !== 'undefined' ? customization['pwa_title'] : 'VCmobile'}</h1>
          </div>
          <div className="fields-container">
            <div className="center">
              <Checkbox onData={(_, value) => setChecked(value)}>
                Acepto los <Link to="/terms-and-conditions">terminos y condiciones</Link> y el <Link to="/privacy-policy">aviso de privacidad</Link>.
              </Checkbox>
            </div>
          </div>
          <div className="buttons">
            <button className="primary" disabled={!checked} onClick={() => acceptTerms()}>Continuar</button>
          </div>
        </Modal>
      }
    </div>
  )
})

export default SplashScreen