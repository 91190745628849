import React, { useState, useEffect, useCallback } from 'react';

const Radio = ({name, label, options, initialValue, onData}) => {
  const [value, setValue] = useState(initialValue)

  const _onData = useCallback(onData, [])

  const handleChange = (optionValue) => {
    setValue(optionValue)
    if (onData) _onData(name, optionValue)
  }

  useEffect(() => {
    if (initialValue) _onData(name, initialValue)
  }, [initialValue, _onData, name])

  return (
    <div className="field">
      <label>{label}</label>
      <div className='radio'>
        { options.map((option) => {
            return (
              <div key={ "radio_" + name + '_' + option[0] } className={ "button" + (option[0] === value ? ' selected' : '') } onClick={() => { handleChange(option[0]) }}>
                { option[1] }
              </div>
            )
        })}
      </div>
    </div>
  )
}

export default Radio