import React, { useState, useEffect, useCallback, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import Header from './Header'
import Request from '../helpers/Request'
import Storage from '../helpers/Storage'
import MachineImage from '../images/machine.png'
import '../css/Purchases.css'

const READY = "READY"
const SUCCESS = "SUCCESS"
const ERROR = "ERROR"
const CANCELED = "CANCELED"
const BUSY = "BUSY"
const CONNECTING = "CONNECTING"

const PurchasesListenerScreen = withRouter(({ history }) => {
  const [purchases, setPurchases] = useState([])
  const [fetching, setFetching] = useState(false)
  const profile = Storage.getProfile()
  const timer = useRef(null)
  const autovend = (Storage.get("autovend") === 'true');
  const autovend_machines = autovend ? JSON.parse(Storage.get("autovend_machines")) : null;
  let currentState = "LISTENING"

  const onData = (key, value) => {
    
  }

  const purchaseDetails = (purchase) => {
    history.push('/purchase', { purchase: purchase })
  }

  const checkPurchases = useCallback(() => {
    var params = {
      machines: autovend_machines,
    }
    setFetching(true)
    Request.post('/machines/purchases', params).then((data) => {
      console.log(data)
      setPurchases(data)
      setFetching(false)
    }).catch((error) => {
      setFetching(false)
    })
  }, [])

  useEffect(() => {
    if (profile.id_user === null) {
      if (timer.current !== null) clearInterval(timer.current)
      timer.current = null
    } else {
      if (timer.current !== null) clearInterval(timer.current)
      timer.current = setInterval(checkPurchases, 5000)
    }
  }, [profile, checkPurchases, currentState])

  const cancelSearch = () => {
    clearInterval(timer.current)
    timer.current = null
    history.go(-1)
  }

  return (
    <div>
      <Header secondaryButton={'NONE'}/>
      <div className="title-list">
        <h1>Últimas compras</h1>
        <button className='secondary' onClick={() => checkPurchases()}>Actualizar</button>
      </div>
      <div className="purchases-list">
        {!fetching && purchases.length == 0 &&
          <div className="empty-state">No se han encontrado compras</div>
        }
        { purchases && purchases.map((purchase) => {
          return (
            <div key={ purchase.id_purchase } className="purchase-card">
              <div className="purchase-data" /* onClick={() => purchaseDetails(purchase)} */>
                <div className="purchase-card_top">
                  <div className="purchase-date">{ purchase.created }</div>
                  <div className="purchase-machine">Maquina: { purchase.machine_name }</div>
                  <div className="purchase-price">${ purchase.product_price !== null ? purchase.product_price : '0.00' }</div>
                </div>
                <div className="purchase-card_top">
                  <div className="purchase-product">{ purchase.product_name !== null ? purchase.product_name : "Producto no registrado" }</div>
                  { purchase.status === ERROR &&
                    <div className="purchase-status">Error en compra</div>
                  }
                  { purchase.status === CANCELED &&
                    <div className="purchase-status">Cancelado</div>
                  }
                  { purchase.status === SUCCESS &&
                    <div className="purchase-status">Compra exitosa</div>
                  }
                  { purchase.status === READY &&
                    <div className="purchase-status">En proceso</div>
                  }
                </div>
              </div>
            </div>
          )
        }) }
      </div>
    </div>
  )
})

export default PurchasesListenerScreen