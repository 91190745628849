import request from 'request-promise'
import Storage from '../helpers/Storage'
import CryptoJS from 'crypto-js'

const PRODUCTION_URL = "https://vct.cloud/api/cashless"
const DEVELOPMENT_URL = "http://api.vendicenter.web/api/cashless"
class Request {
  constructor() {
    this.base_url = PRODUCTION_URL
    this.headers = {
      "Content-Type": "multipart/form-data",
    }
    this.errorMessages = {
      "USER_NOT_FOUND": "El usuario no se encontró en el sistema.",
      "EMPTY_RESULT": "No se encontraron resultados en el sistema."
    }
  }

  doRequest(options) {
    return new Promise((resolve, reject) => {
      // TODO: this is an ugly hack
      var credentials = Storage.getCredentials()
      if (credentials) this.headers['Access-Token'] = credentials.access_token
      request(options).then((body) => {
        try {
          // New request response handling
          // TODO: resolve part
          // For errors, reject the promise with the message from this.errorMessages, and the error code from response
          if (body.status === "success") {
            // TODO: check if this is correct for the message
            resolve(body.message)  
          } else if (body.status === "error") {
            const { error_code } = body
            reject({ code: error_code, message: this.errorMessages[error_code] })
          }
          resolve(body.message)  
        } catch (error) {
          console.error(error)
          reject({ code: 'NETWORK_ERROR', message: 'Falla de internet, intente de nuevo más tarde.' })
        }
      }).catch((body) => {
        try {
            const error = body.error
          if (error.status === "error") {
              console.error(error)
              const { error_code } = error
              reject({ code: error_code, message: 'Error interno, intente de nuevo más tarde.' })
            }
        } catch (error) {
          console.log(error)
          reject({ code: 'NETWORK_ERROR', message: 'Falla de internet, intente de nuevo más tarde.' })
        }
      })  
    })
  }

  get(url, params = null, options = { json: true }) {
    var data = {
      method: 'GET',
      uri: this.base_url + url,
      headers: this.headers
    }
    if (params) data.qs = params
    data.json = true
    return this.doRequest(data)
  }

  post(url, params = null, options = { json: true }) {
    var data = {
      method: 'POST',
      uri: this.base_url + url,
      headers: this.headers
    }

    // Oauth2.0 Resource Owner Password Credentials Grant
    if (url === '/login') {
      const emailPass = `${params["email"]}:${params["password"]}`
      data.headers["Authorization"] = `Basic ${CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(emailPass))}`
    }

    if (url === '/login/pin') {
      const userIdPass = `${params["user_id"]}:${params["pin"]}`
      data.headers["Authorization"] = `Basic ${CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(userIdPass))}`
    }

    if (params) data.body = params
    data.json = true
    data.headers['Content-Type'] = 'application/json'
    return this.doRequest(data)
  }

  customPost(url, params = null, options = { json: true }) {
    var data = {
      method: 'POST',
      uri: url,
      headers: this.headers
    }

    // Oauth2.0 Resource Owner Password Credentials Grant
    if (url === '/login') {
      const emailPass = `${params["email"]}:${params["password"]}`
      data.headers["Authorization"] = `Basic ${CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(emailPass))}`
    }

    if (url === '/login/pin') {
      const userIdPass = `${params["user_id"]}:${params["pin"]}`
      data.headers["Authorization"] = `Basic ${CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(userIdPass))}`
    }


    if (params) data.body = params
    data.json = true
    data.headers['Content-Type'] = 'application/json'
    return this.doRequest(data)
  }

  IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }
}

export default new Request()