
export default class FormData {
  constructor(initialData = {}) {
    this.formData = initialData
    this.invalidFields = {}
  }

  onData() {
    return this.updateData.bind(this)
  }

  updateData(field, value, isValid) {
    this.formData[field] = value
    if (isValid) {
      delete this.invalidFields[field]
    } else {
      this.invalidFields[field] = true
    }
  }

  allValid() {
    return Object.keys(this.invalidFields).length === 0
  }

  data(field = undefined) {
    if (!field) return this.formData
    return this.formData[field]
  }
}