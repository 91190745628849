import React from 'react'
import { withRouter } from 'react-router-dom';
import Header from './Header';

const PrivacyPolicyScreen = withRouter(({ history }) => {
  return (
    <div>
      <Header label="Aceptar" onClick={() => history.go(-1)} />
      <div className="normal-padding">
        <h3>AVISO DE PRIVACIDAD</h3>
        <p><b>Integradora de Espectáculos de Guadalajara S.A. de C.V.</b> (“El Responsable”), con domicilio en la zona conurbada de la ciudad de Guadalajara, Jalisco, México que comprende los municipios de Guadalajara, Zapopán, Tlaquepaque y Tonalá, El Salto y Tlajomulco de Zúñiga en cumplimiento con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y demás disposiciones aplicables (en lo sucesivo la “Ley”),  le informa que es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección, por lo que pone a su disposición el presente Aviso de Privacidad.</p>
        
        <h4>Información:</h4>
        <p>Los datos que usted como suscriptor o usuario nos proporcione, serán tratados bajo los principios establecidos en la Ley (licitud, rectitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad).</p>
        <p>Para las finalidades señaladas en el presente Aviso de Privacidad, utilizaremos los siguientes datos personales:</p>
        <p><b>Datos indispensables para el registro de Usuarios Cashless</b>: correo electrónico y contraseña.</p>
        
        <h4>Finalidades del tratamiento de sus Datos Personales:</h4>
        <ul>
          <li>Contactarlo para pedir opinión sobre marcas, productos y servicios;</li>
          <li>Enviarle información sobre promociones, experiencias personalizadas, campañas y nuevos productos de la marca Cashless;</li>
        </ul>
        <p>Compartir la información con empresas, redes sociales y demás dependencias u organizaciones afiliadas o que tengan un convenio con Cashless o cualquier filial de <b>Integradora de Espectáculos de Guadalajara S.A. de C.V.</b></p>

        <h4>Transferencia de sus Datos Personales:</h4>
        <p>Le informamos que sus datos personales serán transferidos a personas físicas y/o morales distintas a las que forman parte del equipo técnico de <b>Integradora de Espectáculos de Guadalajara S.A. de C.V.</b>, con los cuales exista un convenio de comercialización con los datos vertidos en el Sistema Cashless.</p>
        
        <h4>Ejercicio de los Derechos:</h4>
        <p>En todo momento, Usted tiene derecho de acceder a los datos personales que poseamos y a los detalles del tratamiento de los mismos, así como a rectificarlos en caso de ser inexactos o solicitar su cancelación cuando considere que resulten ser excesivos o innecesarios para las finalidades que justificaron su obtención u oponerse al tratamiento de los mismos, mediante la solicitud respectiva en escrito libre que cumpla con los requisitos establecidos en la Ley. En caso de que requiera información sobre dicha solicitud, podrá contactar a nuestro centro de atención encargado de la Privacidad, enviando un correo electrónico a la dirección: <a href="mailto:asistencia@grupobiz.com.mx">asistencia@grupobiz.com.mx</a> o mediante  asistencia vía telefónica al número <a href="tel:+523322820112">+52 33-22-82-01-12</a></p>
        <p>Una vez recibida su solicitud, le comunicaremos la determinación adoptada dentro del plazo que establece la Ley y en caso de que sea procedente, se hará efectiva de acuerdo a los plazos señalados por la misma.</p>
        <p>Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales desde el momento de acceder su código de acceso. Para revocar su consentimiento deberá de acceder a la configuración de la aplicación o enviar un correo electrónico a la dirección: <a href="mailto:asistencia@grupobiz.com.mx">asistencia@grupobiz.com.mx</a> o mediante  asistencia vía telefónica al número <a href="tel:+523322820112">+52 33-22-82-01-12</a>, en los términos que se suscribió para el ejercicio de sus Derechos.</p>
        
        <h4>Limitación del uso y divulgación de sus datos:</h4>
        <p>El Responsable tiene el compromiso de resguardar sus datos personales bajo estrictas medidas de seguridad físicas, técnicas y administrativas, las cuales han sido implementadas, conforme al Reglamento de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y demás disposiciones legales aplicables,  con el objeto de proteger dichos datos personales contra cualquier daño, pérdida, alteración, destrucción, uso, acceso o tratamiento no autorizados, y buscando, ante todo garantizar la confidencialidad de los datos y asegurarnos de minimizar cualquier riesgo o vulneración que pudiera presentarse.</p>

        <h4>Cambios al Aviso de Privacidad:</h4>
        <p>El presente Aviso de Privacidad podrá ser modificado en el futuro. En todo caso, cualquier modificación al mismo se hará de su conocimiento mediante notificación en la página principal de la aplicación o mediante el correo electrónico proporcionado para el registro de su cuenta.</p>
        
        <p>Fecha de la última Actualización 07 siete de Noviembre de 2019 Dos Mil Diecinueve.</p>
      </div>
    </div>
  )
})

export default PrivacyPolicyScreen