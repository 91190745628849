import {useState, useEffect, useCallback} from 'react'

export const usePosition = (watch = false) => {
  const [position, setPosition] = useState({})
  const [error, setError] = useState(null)
  const [tryHighAccuracy, setTryHighAccuracy] = useState(true)

  const onChange = ({coords, timestamp}) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
      accuracy: coords.accuracy,
      timestamp,
    });
  };

  // TODO: how to clear the error
  const onError = useCallback((error) => {
    if (error.code === 3 && tryHighAccuracy && !watch) {
      setTryHighAccuracy(false)
    } else {
      setError(error.message)
    }
  }, [tryHighAccuracy, watch])

  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      setError('Geolocation is not supported')
      return
    }

    var settings
    if (tryHighAccuracy) {
      settings = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
        distanceFilter: 200,
      }
    } else {
      settings = {
        enableHighAccuracy: false,
        timeout: 15000,
        maximumAge: 0,  
        distanceFilter: 200,    
      }
    }

    // TODO: how to retry?
    let watcher = null;
    if (watch) {
      watcher = geo.watchPosition(onChange, onError, settings)
    } else {
      geo.getCurrentPosition(onChange, onError, settings)
    }

    return () => watcher && geo.clearWatch(watcher)
  }, [tryHighAccuracy, watch, onError])

  return { ...position, error, waiting: !error && !position.latitude }
}