import React, { useState, useEffect, useCallback } from 'react';

const Checkbox = ({name, children, intiallyChecked = false, onData}) => {
  const [checked, setChecked] = useState(intiallyChecked)

  const _onData = useCallback(onData, [])

  const toggleCheckbox = () => {
    const toggled = !checked
    setChecked(toggled)
    if (onData) _onData(name, toggled)
  }

  useEffect(() => {
    if (intiallyChecked) _onData(name, intiallyChecked)
  }, [intiallyChecked, _onData, name])

  return (
    <div className="field">
      <div className="checkbox">
        <span className={`box ${checked ? 'selected icon-ok' : ''}`} onClick={toggleCheckbox}></span>
        <label>{children}</label>
      </div>
    </div>
  )
}

export default Checkbox