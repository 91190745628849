import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom';
import Request from '../helpers/Request';
import Storage from '../helpers/Storage';
import '../css/FundsFooter.css'

const FundsFooter = withRouter(({purchase_enabled, history, onBalance}) => {
  const [fetching, setFetching] = useState(true)
  const [balance, setBalance] = useState(false)
  const [bonusBalance, setBonusBalance] = useState(false)
  const [fundsToShow, setFundsToShow] = useState(false)
  const [fundsToShowType, setFundsToShowType] = useState(false)
  const _onBalance = useCallback(onBalance, [])
  const autovend = (Storage.get("autovend") === 'true');
  const productControl = (Storage.get("product_control") === 'true');

  const getFunds = useCallback(() => {
    setFetching(true)
    Request.get('/profile', null).then((data) => {
      console.log(data)
      setFetching(false)
      Storage.setProfile(data)
      setBalance(data.funds / 100)
      setBonusBalance(data.bonus_funds / 100)
      data['funds_show'] !== undefined ? setFundsToShow(data.funds_show) : setFundsToShow(data.funds)
      setFundsToShowType(data.funds_show_type)
      if (_onBalance) _onBalance(data.funds / 100)
    }).catch((error) => {
      setFetching(false)
      // TODO: show error?
    })
  }, [_onBalance])

  useEffect(() => {
    getFunds()
  }, [getFunds])

  return (
    <div>
      {!productControl && !autovend &&
        <div className="funds-footer">
          {purchase_enabled &&
            <div className="funds-summary">
              <div className="label">Saldo actual:</div>
              { fetching || balance === false ?
                <div className="icon-spin spin"></div>
                :
                <div className="value">$<span>{ fundsToShow } </span></div>
              }
              { fundsToShowType === "FUNDS" &&
                <div className="funds-footer_type">Saldo regular</div>
              }
              { fundsToShowType === "COMPANY_FUNDS" &&
                <div className="funds-footer_type">Saldo empresarial</div>
              }
              { fundsToShowType === "BONUS_FUNDS" &&
                <div className="funds-footer_type">Saldo bonus</div>
              }
            </div>
          }
          {/* {bonusBalance && bonusBalance !== 0 &&
            <div className="funds-summary">
              <div className="label">Vendicreditos:</div>
              <div className="value"><span>{ bonusBalance.toFixed(2) }</span></div>
            </div>
          } */}
          {purchase_enabled &&
            <button className="primary add-funds" onClick={() => {history.push('/add-funds')}}>Agregar Fondos</button>
          }
        </div>
      }
    </div>
  )
})

export default FundsFooter