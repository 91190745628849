import React from 'react'

const Alert = ({onClick, children}) => {

  return (
    <div>
      <div className='overlay'></div>
      <div className='alert-container'>
        <div className='alert'>
          <div className='normal-padding center'>
            {children}
          </div>
          <button className='primary' onClick={onClick}>Aceptar</button>
        </div>
      </div>
    </div>
  )
}

export default Alert