import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom'
import Field from '../components/Field';
import Header from './Header'
import FormData from '../helpers/FormData'
import Request from '../helpers/Request';
import Modal from '../components/Modal';
import Alert from '../components/Alert'
import Timeout from '../components/Timeout';
import Storage from '../helpers/Storage'

const AddCardScreen = withRouter(({ history, onNewPaymentSource }) => {
  const [isRequesting, setRequesting] = useState(false)
  const [error, setError] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const defaultMachine = Storage.get('default_machine')
  const formData = useRef(new FormData())

  const nameValidate = (data) => {
    if (!/^[a-záéíóúÁÉÍÓÚñÑ .]+$/i.test(data)) {
      return 'Solo puede tener letras, puntos y espacios'
    }
    return false
  }

  const cardNumberValidate = (data) => {
    if (!/^[0-9 ]*$/i.test(data)) {
      return 'Solo puede tener letras, puntos y espacios'
    }
    if (!/^[0-9]{16}$/i.test(data && data.replace(/\s/g, ''))) {
      return 'Deben de ser 16 números'
    }
    return false
  }

  const cardMonthValidate = (data) => {
    if (!/^(0[1-9]|1[0-2])$/i.test(data)) {
      return 'Mes inválido'
    }
    return false
  }

  const cardYearValidate = (data) => {
    if (!/^(19|[2-3][0-9])$/i.test(data)) {
      return 'Año inválido'
    }
    return false
  }

  const cardCVVValidate = (data) => {
    if (!/^[0-9]{3}$/i.test(data)) {
      return 'CVV inválido'
    }
    return false
  }

  const addCard = () => {
    if (formData.current.allValid()) {
      setRequesting(true)
      var params = {
        name: formData.current.data("card_name"),
        pan: formData.current.data("card_number"),
        cvv2: formData.current.data("card_cvv"),
        expDate: formData.current.data("card_expire_year") + formData.current.data("card_expire_month")
      }
      Request.post('/add_card', params).then((data) => {
        setRequesting(false)
        if (onNewPaymentSource) onNewPaymentSource(data.id_payment_source)
        setSuccessMessage('Se ha agregado la tarjeta')
      }).catch((error) => {
        setRequesting(false)
        setError('No fue posible guardar los datos de la tarjeta. Por favor verifique los datos e intente de nuevo.')
      })
    }
  }

  const closeSession = () => {
    Storage.setCredentials({})
    history.push('/')
  }

  return (
    <div>
      <Header secondaryButton={'CANCEL'} />
      <div className="title">
        <h1>Agregar nueva tarjeta</h1>
      </div>
      { error &&
        <Modal>
          <div className="error-modal">
            <i className="icon-attention orange"></i>
            <div>{error}</div>
          </div>
          <div className="buttons">
            <button className="primary" onClick={() => setError(false)}>Aceptar</button>
          </div>
        </Modal>
      }
      <div className="fields-container">
        <Field 
          name='card_name' 
          label='Nombre en la tarjeta' 
          placeholder='ej. JUAN PEREZ'
          maxLength={100}
          onValidate={nameValidate}
          onData={formData.current.onData()} />
        <Field 
          name='card_number' 
          label='Número de tarjeta' 
          placeholder='ej. 1234 1234 1234 1234'
          maxLength={16}
          onValidate={cardNumberValidate}
          onData={formData.current.onData()}
          autocomplete="off" />
        <div className="row">
          <Field 
            name='card_expire_month' 
            label='Mes' 
            placeholder='ej. 12'
            maxLength={2}
            onValidate={cardMonthValidate}
            onData={formData.current.onData()} />
          <Field 
            name='card_expire_year' 
            label='Año' 
            placeholder='ej. 25'
            maxLength={2}
            onValidate={cardYearValidate}
            onData={formData.current.onData()} />
        </div>
        <Field 
          name='card_cvv' 
          label='CVV' 
          placeholder='ej. 123'
          maxLength={3}
          onValidate={cardCVVValidate}
          onData={formData.current.onData()}
          type="password"
          autoComplete="off" />
      </div>
      <div className='buttons'>
        <button className='primary' onClick={addCard} disabled={isRequesting}>Guardar</button>
      </div>
      { defaultMachine !== null &&          
        <div className='banner red'>
            <Timeout minutes={2} pause={isRequesting} onTimeout={() => { closeSession() }}>Desconexión en</Timeout>
        </div>
      }
      { successMessage &&
        <Alert onClick={() => { history.go(-1) }}>
          <span className='icon-credit-card icon-size-large icon-color-green'></span>
          {successMessage}
        </Alert>
      }
    </div>

  )
})

export default AddCardScreen