import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom'
import Header from './Header'
import { usePosition } from '../helpers/UsePositionHook';
import Storage from '../helpers/Storage'
import Request from '../helpers/Request';
import Modal from '../components/Modal';
import Checkbox from '../components/Checkbox'
import Select from '../components/Select'
import '../css/Settings.css'
import '../css/App.css'
import MachinesData from '../machines-data.json'

const SettingsScreen = withRouter(({ history, onNewPaymentSource }) => {
  const [errorMessage, setErrorMessage] = useState(false)
  const [success, setSuccess] = useState(false)
  const [autovendMachines, setAutovendMachines] = useState([])
  const [productControlMachines, setProductControlMachines] = useState([])
  const [selected, setSelected] = useState(false)
  const [tabletMachines, setTabletMachines] = useState(false)
  const [tabletMode, setTabletMode] = useState(false)
  const [autoVendMode, setAutoVendMode] = useState(false)
  const [productControlMode, setProductControlMode] = useState(false)
  const [machinesNewModes, setMachinesNewModes] = useState([])
  const [machinesPControlMode, setMachinesPControlMode] = useState([])
  const {latitude, longitude, accuracy, error} = usePosition()
  const defaultMachine = Storage.get('default_machine')
  const autovendCurrentStatus = Storage.get('autovend')
  const productControlCurrentStatus = Storage.get('product_control')
  const autovendCurrentMachines = Storage.get('autovend_machines') !== null ? JSON.parse(Storage.get('autovend_machines')) : {}
  const productControlCurrentMachines = Storage.get('pcontrol_machines') !== null ? JSON.parse(Storage.get('pcontrol_machines')) : {}

  const onData = (key, value) => {
    setSelected(value)
  }

  useEffect(() => {
    setMachinesNewModes(autovendCurrentMachines)
    setMachinesPControlMode(productControlCurrentMachines)
  }, [])

  const getTabletMachines = useCallback(() => {
    let selectTabletMachines = []
    setTabletMachines(selectTabletMachines)
    var options = {
      lat: latitude,
      lon: longitude,
      rad: 500000 + accuracy
    }

    Request.get('/machines/nearest', options).then((data) => {
      if(data != null) {
        data.forEach(function(value, i) {
          let selectMachinesOptions = [i, data[i].machine_name]
          selectTabletMachines.push(selectMachinesOptions)
        })
      }
      setTabletMachines(selectTabletMachines)
    }).catch((error) => {
      if (error.code === 'AUTHORIZATION_ERROR') {
        setErrorMessage("No se han podido obtener las máquinas.")
      }
    })
  }, [latitude, longitude, accuracy])

  const getAutovendMachines = useCallback(() => {
    let machineOptions = []
    //setAutovendMachines(machineOptions)
    var options = {
      lat: latitude,
      lon: longitude,
      rad: 500000 + accuracy
    }

    Request.get('/machines/nearest/autovend', options).then((data) => {
      if(data != null) {
        data.forEach(function(value, i) {
          let machineObject = {
            "id_machine" : data[i].id_machine,
            "machine_name" : data[i].machine_name,
            "id_company" : data[i].id_company,
            "machine_address" : data[i].machine_address,
            "machine_location" : data[i].machine_location,
            "subdomain" : data[i].subdomain,
            "distance" : data[i].distance
          }
          machineOptions.push(machineObject)
        })
      }
      setAutovendMachines(machineOptions)
    }).catch((error) => {
      if (error.code === 'AUTHORIZATION_ERROR') {
        setErrorMessage("No se han podido obtener las máquinas.")
      }
    })
  }, [latitude, longitude, accuracy])

  const getProductControlMachines = useCallback(() => {
    let machineOptions = []
    //setProductControlMachines(machineOptions)
    var options = {
      lat: latitude,
      lon: longitude,
      rad: 500000 + accuracy
    }

    Request.get('/machines/nearest/productcontrol', options).then((data) => {
      if(data != null) {
        data.forEach(function(value, i) {
          let machineObject = {
            "id_machine" : data[i].id_machine,
            "machine_name" : data[i].machine_name,
            "id_company" : data[i].id_company,
            "machine_address" : data[i].machine_address,
            "machine_location" : data[i].machine_location,
            "subdomain" : data[i].subdomain,
            "distance" : data[i].distance
          }
          machineOptions.push(machineObject)
        })
      }
      setProductControlMachines(machineOptions)
    }).catch((error) => {
      if (error.code === 'AUTHORIZATION_ERROR') {
        setErrorMessage("No se han podido obtener las máquinas.")
      }
    })
  }, [latitude, longitude, accuracy])

  // Perform the backend requests
  useEffect(() => {
    if (latitude && longitude) {
      getTabletMachines()
      getAutovendMachines()
      getProductControlMachines()
    }
  }, [latitude, longitude, accuracy, getTabletMachines, getAutovendMachines, getProductControlMachines])

  const selectedMachine = (machine, value) => {
    let machines = machinesNewModes;
    machine.mode = value === true ? "autovend" : "none"
    machines[machine.id_machine] = machine
    setMachinesNewModes(machines)
  }

  const selectedProductControlMachine = (machine, value) => {
    let machines = machinesPControlMode;
    machine.mode = value === true ? "product_control" : "none"
    machines[machine.id_machine] = machine
    setMachinesPControlMode(machines)
  }

  const handleChange = selectedOption => {
    setSelected(selectedOption.value)
  };

  const handleAutovendMode = (value) => {
    setAutoVendMode(value)
    if(!value) {
      //machinesNewModes = {}
    }
  };

  const handleProductControlMode = (value) => {
    setProductControlMode(value)
  };
  
  const saveChanges = () => {
    if(tabletMode) {
      Storage.set('default_machine', JSON.stringify(autovendMachines[selected]))
    } else if (autoVendMode) {
      Storage.delete('default_machine')
      if (productControlCurrentStatus && !productControlMode) {
        manageProductControlMode("DISABLE_PCONTROL")
      }
      manageAutoVendMode("ENABLE_AUTOVEND")
    } else if (productControlMode) {
      Storage.delete('default_machine')
      if (autovendCurrentStatus && !autoVendMode) {
        manageAutoVendMode("DISABLE_AUTOVEND")
      }
      manageProductControlMode("ENABLE_PCONTROL")
    }

    if (autovendCurrentStatus && !autoVendMode) {
      manageAutoVendMode("DISABLE_AUTOVEND")
      Storage.delete('autovend')
      Storage.delete('autovend_machines')
    }
    if (productControlCurrentStatus && !productControlMode) {
      manageProductControlMode("DISABLE_PCONTROL")
      Storage.delete('product_control')
      Storage.delete('pcontrol_machines')
    }

    //TODO: Add success message

    //redirect()
  }

  const manageAutoVendMode = (value) => {
    let params = {
      mode: value,
      machines: value === "ENABLE_AUTOVEND" ? machinesNewModes : autovendCurrentMachines
    }
    if((machinesNewModes.length !== 0 && typeof machinesNewModes.length !== 'undefined' && autoVendMode === true) || autoVendMode !== autovendCurrentStatus) {
      Request.post('/machines/auto_vend', params).then((data) => {
        Storage.set('autovend', autoVendMode)
        Storage.delete('product_control')
        Storage.delete('pcontrol_machines')
        Object.keys(machinesNewModes).forEach(key => {
          if(machinesNewModes[key]["mode"] !== "autovend"){
            let machines = machinesNewModes
            delete machines[key]
            setMachinesNewModes(machines)
          }
        });
        Storage.set('autovend_machines', JSON.stringify(machinesNewModes))
        setSuccess("Los cambios han sido guardados correctamente")
      }).catch((error) => {
        Storage.delete('autovend')
        Storage.delete('autovend_machines')
        setErrorMessage("Error guardando las máquinas seleccionadas.")
      })
      if(autoVendMode !== autovendCurrentStatus) {
        Storage.set('autovend', autoVendMode)
      }
    }
  };

  const manageProductControlMode = (value) => {
    if(productControlMode && !tabletMode && !autoVendMode) {
      let params = {
        mode: value,
        machines: value === "ENABLE_PCONTROL" ? machinesPControlMode : productControlCurrentMachines
      }
      Request.post('/machines/product_control', params).then((data) => {
        Storage.set('product_control', productControlMode)
        Storage.delete('autovend')
        Storage.delete('autovend_machines')
        Object.keys(machinesPControlMode).forEach(key => {
          if(machinesPControlMode[key]["mode"] === "none") {
            let machines = machinesPControlMode
            delete machines[key]
            setMachinesNewModes(machines)
          }
        });
        Storage.set('pcontrol_machines', JSON.stringify(machinesPControlMode))
        setSuccess("Los cambios han sido guardados correctamente")
      }).catch((error) => {
        Storage.delete('product_control')
        Storage.delete('pcontrol_machines')
        setErrorMessage("Error guardando las máquinas seleccionadas.")
      })
    }
  };

  const handleCancelButton = (value) => {
    history.push('/machines')
  };

  const redirect = useCallback(() => {
    var credentials = Storage.getCredentials()
    if (credentials.access_token) {
      history.push('/machines')
    } else {
      history.push('/login')
    }      
  }, [history])

  return (
    <div>
      <Header secondaryButton={'GOBACK'} onClick={() => handleCancelButton()}/>
      <div className="title">
        <h1>Ajustes</h1>
      </div>
      { !latitude &&
        <div className="machine-search">
          <i className="icon-spin icon-size-medium spin"></i>
          <div>Cargando máquinas...</div>
        </div>
      }
      { errorMessage &&
        <Modal>
          <div className="error-modal">
            <i className="icon-attention orange"></i>
            <div>{errorMessage}</div>
          </div>
          <div className="buttons">
            <button className="primary" onClick={() => setErrorMessage(false)}>Aceptar</button>
          </div>
        </Modal>
      }
      { success &&
        <Modal>
          <div className="success-modal">
            <i className="icon-ok green"></i>
            <div>{success}</div>
          </div>
          <div className="buttons">
            <button className="primary" onClick={() => setSuccess(false)}>Aceptar</button>
          </div>
        </Modal>
      }
      <div className="fields-container">
        {error && 
          <p className="red">Asegurate que la aplicación tiene permisos para acceder a tu ubicación.</p>
        }
        <p className="settings-list-description">Estas son características avanzadas, activarlas modifica drasticamente el funcionamiento de la aplicación.</p>
        <h3>Modifica interfaz de la aplicación.</h3>
        <div className="fields-container">
          { !autoVendMode && !productControlMode &&
            <Checkbox onData={(_, value) => setTabletMode(value)} intiallyChecked={defaultMachine !== null}>
                Usar en una sola máquina.
            </Checkbox>
          }
          { tabletMode && tabletMachines.length === 0 &&
            <div className="settings-list-item">No hay maquinas disponibles</div>
          }
          { tabletMode && !autoVendMode && !productControlMode && tabletMachines.length > 0 &&
            <Select
              name="basic-single" 
              label="Selecciona máquina" 
              options={tabletMachines !== null ? tabletMachines : []}
              onData={onData}
              onChange={handleChange}/>
          }
        </div>
        { !tabletMode &&
          <h3>Modifica funcionamiento de la máquina.</h3>
        }
        <div className="fields-container">
          { !tabletMode && !productControlMode &&
            <div>
              <p>Selecciona máquinas a gestionar.</p>
              <Checkbox onData={(_, value) => handleAutovendMode(value)} intiallyChecked={autovendCurrentStatus == "true"}>
                  Modo Auto-Vend
              </Checkbox>
            </div>
          }

          { autoVendMode && autovendMachines.length === 0 && !tabletMode && 
            <div className="settings-list-item">No hay maquinas disponibles</div>
          }

          { autoVendMode && autovendMachines.length !== 0 && !tabletMode &&
            autovendMachines.map((machine) => {
              let selected = false
              if (autovendCurrentMachines !== null) {
                Object.keys(autovendCurrentMachines).forEach(key => {
                  if(!selected && autovendCurrentMachines[key]["mode"] === "autovend"){
                    selected = `${machine["id_machine"]}` === key ? true : false
                  }
                });
              }
              return (
                <div className="settings-list-item">
                  <Checkbox onData={(_, value) => selectedMachine(machine, value)} intiallyChecked={selected}>
                    {machine["machine_name"]}
                  </Checkbox>
                </div>
              )
            })
          }

          { !tabletMode && !autoVendMode && 
            <div>
              <p>Acceder al modo control de producto.</p>
              <Checkbox onData={(_, value) => handleProductControlMode(value)} intiallyChecked={productControlCurrentStatus === "true"}>
                  Modo Product-Control
              </Checkbox>
            </div>
          }
          
          { productControlMode && productControlMachines.length === 0 && !tabletMode && 
            <div className="settings-list-item">No hay maquinas disponibles</div>
          }

          { productControlMode && productControlMachines.length !== 0 && !tabletMode &&
            productControlMachines.map((machine) => {
              let selected = false
              if (autovendCurrentMachines !== null) {
                Object.keys(productControlCurrentMachines).forEach(key => {
                  if(!selected && productControlCurrentMachines[key]["mode"] === "product_control"){
                    selected = `${machine["id_machine"]}` === key ? true : false
                  }
                });
              }
              return (
                <div className="settings-list-item">
                  <Checkbox onData={(_, value) => selectedProductControlMachine(machine, value)} intiallyChecked={selected}>
                    {machine["machine_name"]}
                  </Checkbox>
                </div>
              )
            })
          }
        </div>
      </div>
      <div className="buttons">
        <button className="primary" onClick={() => saveChanges()}>Continuar</button>
      </div>
    </div>

  )
})

export default SettingsScreen