import React, { useState, useEffect } from 'react'

const Timeout = ({ children, onTimeout, minutes, seconds, pause = false, showOnLast = false }) => {
  const [totalSeconds, setTotalSeconds] = useState((minutes ? minutes * 60 : 0) + (seconds ? seconds : 0))

  const renderTime = (secs) => {
    const min = Math.floor(secs / 60)
    const sec = secs % 60
    if (min >= 1) return `${min} minutos`
    return `${sec} segundos`
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (totalSeconds > 0) {
        if (!pause) setTotalSeconds(totalSeconds - 1)
      } else if (totalSeconds === 0) {
        if (!pause) setTotalSeconds(totalSeconds - 1)
        clearInterval(interval)
        onTimeout()
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [onTimeout, totalSeconds, pause])


  return (
    <div>
      {showOnLast &&
        <span className={totalSeconds > showOnLast ? "hidden":""}>{children} { renderTime(totalSeconds) }</span>
      }
      {!showOnLast &&
        <span>{children} { renderTime(totalSeconds) }</span>
      }
    </div>
  )
}

export default Timeout