import React, { useState, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import FormData from '../helpers/FormData'
import Request from '../helpers/Request'
import Field from '../components/Field'
import Alert from '../components/Alert'

const PasswordRecoveryScreen = withRouter(({ history }) => {
  const formData = useRef(new FormData({}))
  const [error, setError] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const [recoverySuccess, setRecoverySuccess] = useState(false)

  const passwordValidate = (data) => {
    if (/^.{0,5}$/i.test(data)) {
      return 'Debe de tener al menos 6 caracteres'
    }
    return false
  }

  const samePasswordValidate = (data) => {
    if (data !== formData.current.data('password')) {
      return 'Las claves no coinciden'
    } else {
      return false
    }
  }

  const changePassword = () => {
    if (formData.current.allValid()) {
      setIsRequesting(true)
      var data = formData.current.data()
      var match = window.location.search.match(/token=([0-9a-zA-Z]+)/)
      if (!match) {
        setError('El token de verificación es invalido, por favor intente recuperar su contraseña de nuevo.')
        return
      }
      data.token = match[1]
      Request.post('/recovery_confirm', data).then((response) => {
        setRecoverySuccess('Se cambiado la contraseña con éxito.')
      }).catch((error) => {
        setIsRequesting(false)
        if (error.data && error.data.error === 404) {
          setError('El código de verificación no existe o ya expiró, por favor intenta de nuevo solicitando la recuperación de la contraseña.')
        } else {
          setError('No fue posible cambiar la contraseña, por favor contacta al administrador para resolver el problema.')
        }
      })
    }
  }

  return (
    <div>
      <div className="title">
        <h1>Cambiar contraseña</h1>
      </div>
      <div className='fields-container'>
        <form>
          <Field 
            name='password' 
            label='Clave'
            type='password' 
            placeholder='Escribe la nueva clave'
            autoComplete='new-password'
            onValidate={passwordValidate}
            onData={formData.current.onData()} />
          <Field 
            name='new_password' 
            label='Repetir nueva clave'
            type='password' 
            autoComplete='new-password'
            onValidate={samePasswordValidate} />
        </form>
      </div>
      <div className='buttons'>
        <button className='' onClick={() => history.push('/')} disabled={isRequesting}>Cancelar</button>
        <button className='primary' onClick={changePassword} disabled={isRequesting}>Cambiar</button>
      </div>
      { error &&
        <Alert onClick={() => { history.push('/') }}>
          <span className='icon-error icon-size-large icon-color-red'></span>
          {error}
        </Alert>
      }
      { recoverySuccess &&
        <Alert onClick={() => { history.push('/login') }}>
          <span className='icon-ok-circled icon-size-large icon-color-green'></span>
          {recoverySuccess}
        </Alert>
      }
    </div>
  )
})

export default PasswordRecoveryScreen