import React, { useState, useEffect, useRef, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import Header from './Header';
import Select from '../components/Select';
import FormData from '../helpers/FormData'
import Request from '../helpers/Request'
import Storage from '../helpers/Storage'
import Field from '../components/Field'
import Alert from '../components/Alert'
import '../css/Machines.css'

/**
 * PRODUCT REPORT CONSTS
 */
const EXPIRED = "EXPIRED"
const STUCK = "STUCK"
const DAMAGED = "DAMAGED"
const INCOMPLETE = "INCOMPLETE"
const EMPTY = "EMPTY"
const OTHER = "OTHER"

const ReportScreen = withRouter(({history}) => {
  const [error, setError] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const [successfulChange, setSuccessfulChange] = useState(false)
  const formData = useRef(new FormData({}))
  const purchase = useRef(history.location.state.purchase)

  const report_types = [
    [STUCK, "Producto atorado"],
    [EMPTY, "Posición vacia"],
    [EXPIRED, "Producto caducado"],
    [DAMAGED, "Producto dañado"],
    [INCOMPLETE, "Producto incompleto"],
    [OTHER, "Otro"]
  ]

  const onData = (key, value) => {
  }

  const saveReport = () => {
    //if (formData.current.allValid()) {
      formData.current.data()["id_company"] = purchase.current.id_company
      formData.current.data()["id_machine"] = purchase.current.id_machine
      formData.current.data()["machine_name"] = purchase.current.machine_name
      setIsRequesting(true)
      Request.post('/add_report', formData.current.data()).then((data) => {
        setIsRequesting(false)
        setSuccessfulChange("Se envió el reporte exitosamente")
      }).catch((error) => {
        setIsRequesting(false)
        setError(error.message)
      })
    //}
  }

  const closeSession = useCallback(() => {
    Storage.setCredentials({})
    history.push('/')
  }, [history])

  const emptyValidation = (data) => {
    return false
  }

  useEffect(() => {
    let data = Storage.getProfile()
  }, [closeSession])

  return (
    <div>
      <Header secondaryButton={'CANCEL'} />
      <div className="title">
        <h1>Nuevo reporte</h1>
      </div>

      <div className='fields-container'>
        <form>
          <Select
            name="report_type" 
            label="Tipo de reporte" 
            options={report_types} 
            onData={formData.current.onData()} />
          <Field 
            name='id_session' 
            label='Id de compra'
            initialValue={purchase.current.id_purchase}
            autoComplete='name'
            onValidate={emptyValidation} 
            disabled={true}
            onData={formData.current.onData()} />
          <Field 
            name='description' 
            label='Descripción' 
            initialValue={null}
            autoComplete='email'
            autoCapitalize='off'
            onValidate={emptyValidation}
            onData={formData.current.onData()} />
        </form>
      </div>
      <div className='buttons'>
        <button className='primary' onClick={saveReport} disabled={isRequesting}>Guardar</button>
      </div>
      { successfulChange &&
        <Alert onClick={() => { history.go(-2) }}>
          <span className='icon-ok-circled icon-size-large icon-color-green'></span>
          {successfulChange}
        </Alert>
      }
      { error &&
        <Alert onClick={() => { setSuccessfulChange(false) }}>
          <span className='error icon-size-large icon-color-green'></span>
          {error}
        </Alert>
      }
    </div>
  )
})

export default ReportScreen