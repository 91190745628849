import React, { useEffect, useState, useRef } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Request from '../helpers/Request'
import Storage from '../helpers/Storage'
import FormData from '../helpers/FormData'
import Field from '../components/Field'
import Alert from '../components/Alert'
import '../css/Login.css'

const QuickLoginScreen = withRouter(({ history }) => {
  const [error, setError] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const [customization, setCustomization] = useState(false)
  const credentials = Storage.getCredentials()
  const formData = useRef(new FormData({
    device_id: credentials.device_id ? credentials.device_id : Math.random() * 1000000000000000000
  }))

  const userIdValidate = (data) => {
    if (!/^[0-9_.-]$/i.test(data)) {
      return 'No tiene el formato correcto'
    }
    return false
  }

  const pinValidate = (data) => {
    if (!/^.{4,6}$/i.test(data)) {
      return 'No tiene el formato correcto'
    }
    return false
  }

  useEffect(() => {
    setCustomization(Storage.getCustomization())
  }, [])

  const login = () => {
    if (formData.current.allValid()) {
      setIsRequesting(true)
      var data = formData.current.data()
      data.user_id = data.user_id.trim()
      Request.post('/login/pin', data).then((data) => {
        Storage.setCredentials(data)
        setIsRequesting(false)
        history.push('/machines')
      }).catch((error) => {
        setIsRequesting(false)
        if (error.code === 'WRONG_CREDENTIALS') {
          setError('El código es invalido.')
        } else if (error.code === 'USER_NOT_FOUND') {
          setError('El usuario no está registrado. Revise que el pin esté bien escrito o cree una cuenta nueva.')
        } else {
          setError(error.message)
        }
        setIsRequesting(false)
      })
    }
  }

  return (
    <div>
      <div className='title'>
        <h1>Iniciar sesión</h1>
        <img src={typeof customization['pwa_logo'] !== 'undefined' ? customization['pwa_logo'] : null} alt='' />
      </div>
      <div className='fields-container'>
        <form>
          <Field 
            name='user_id' 
            label='Id de usuario' 
            inputMode='tel'
            autoFocus
            onValidate={userIdValidate}
            onData={formData.current.onData()}/>
          <Field 
            name='pin' 
            label='Código de inicio de sesión'
            type='password' 
            autoCapitalize='off'
            autoComplete='off'
            onValidate={pinValidate}
            inputMode='tel'
            onData={formData.current.onData()} />
        </form>
      </div>
      <div className='buttons'>
        <button className='primary' onClick={login} disabled={isRequesting}>Ingresar ahora</button>
      </div>
      <div className='center-padding'>
        <span>¿No tienes un código?</span>
      </div>
      <div className='center-padding'>
        <Link to='/login' className='link'>Inicia sesión con correo electronico</Link>
      </div>
      { error &&
      <Alert onClick={() => { setError(false) }}>
        <span className='icon-error icon-size-large icon-color-red'></span>
        {error}
      </Alert>
      }
    </div>
  )
})

export default QuickLoginScreen