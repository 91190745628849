import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import Modal from '../components/Modal';
import Storage from '../helpers/Storage';
import Request from '../helpers/Request'
import '../css/Header.css'

const Header = withRouter(({history, label, onClick, secondaryButton = 'NONE', rightButton = 'NONE'}) => {
  const [menuVisible, setMenuVisible] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [customization, setCustomization] = useState(false)
  const profile = JSON.parse(Storage.get("profile"))
  const purchase_enabled = (Storage.get("purchase_enabled") === 'true');
  const autovend = (Storage.get("autovend") === 'true');
  const productControl = (Storage.get("product_control") === 'true');

  const closeSession = () => {
    /* Request /logout endpont to invalidate the token,
    doesn't need response to delete credentials from app */
    Request.get('/logout')
    Storage.delete('credentials')
    history.push('/')
  }

  useEffect(() => {
    setCustomization(Storage.getCustomization())
  }, [])

  const addCard = () => {
    history.push('/add-card')
  }
  
  return (
    <div className="header-wrapper">
      <div className="header">
        { secondaryButton === 'GOBACK' &&
          <div>
            <button className="flat" onClick={() => onClick()}>Cancelar</button>
          </div>
        }

        { secondaryButton === 'CANCEL' &&
          <div>
            <button className="flat" onClick={() => history.go(-1)}>Cancelar</button>
          </div>
        }

        { secondaryButton === 'CLOSE_SESSION' &&
          <button className="flat" onClick={() => setConfirm(true)}>Cerrar sesión</button>
        }

        { secondaryButton === 'NONE' &&
          <span className="icon-menu menu-button" onClick={() => setMenuVisible(true)}></span>
        }

        { rightButton === 'ADD_CARD' &&
          <button className="flat" onClick={addCard}>Agregar tarjeta</button>
        }

        { label &&
          <button className="flat" onClick={onClick}>{label}</button>
        }
      </div>
      <div className={ "main-menu-wrapper" + ( menuVisible ? ' show' : '' ) } onClick={() => setMenuVisible(false)}>
        <div className={ "main-menu scroll" + ( menuVisible ? ' show' : '' ) }>
          <div className="menu-image">
            <img src={typeof customization['pwa_logo'] !== 'undefined' ? customization['pwa_logo'] : null} alt="" />
            <div>{typeof customization['pwa_title'] !== 'undefined' ? customization['pwa_title'] : ''}</div>
          </div>
          <ul>
            { purchase_enabled && !autovend &&
              <li onClick={() => history.push('/machines')}>Comprar</li>
            }
            { !purchase_enabled && !autovend &&
              <li onClick={() => history.push('/machines')}>Máquinas</li>
            }
            { autovend &&
              <div>
                <li onClick={() => history.push('/purchases-listener')}>Últimas Compras</li>
                <li onClick={() => history.push('/settings')}>Ajustes</li>
              </div>
            }
            { productControl &&
              <li onClick={() => history.push('/settings')}>Ajustes</li>
            }
            <li onClick={() => history.push('/profile')}>Mi perfil</li>
            { purchase_enabled && !autovend && !productControl &&
              <div>
                <li onClick={() => history.push('/purchases')}>Tus compras</li>
                <li onClick={() => history.push('/reports')}>Reportes</li>
                <li onClick={() => history.push('/add-funds')}>Métodos de pago</li>
              </div>
            }
            { profile && profile.administrator === 1 &&
              <div>
                <li onClick={() => history.push('/settings')}>Ajustes</li>
              </div>
            }
            <li onClick={() => history.push('/terms-and-conditions')}>Términos de servicio</li>
            <li onClick={() => history.push('/privacy-policy')}>Política de privacidad</li>
            <li onClick={() => setConfirm(true)}>Cerrar sesión</li>
          </ul>
        </div>
      </div>
      { confirm &&
        <Modal>
          <div className="confirm-modal">
              <i className="icon-attention orange"></i>
              <div>¿Cerrar sesión?</div>
            </div>
            <div className='buttons'>
              <button className='secondary' onClick={() => setConfirm(false)}>Cancelar</button>
              <button className='primary' onClick={() => closeSession()}>Aceptar</button>
            </div>
        </Modal>
      }
    </div>
  )
})

export default Header