import React, { useState, useEffect, useRef, useCallback } from 'react'
import { withRouter, Link } from 'react-router-dom'
import classNames from 'classnames'
import Header from './Header';
import FundsFooter from './FundsFooter';
import Select from '../components/Select';
import Modal from '../components/Modal';
import Alert from '../components/Alert'
import MachineImage from '../images/machine.png'
import MachineAnimated from '../images/machine.gif'
import FormData from '../helpers/FormData'
import Request from '../helpers/Request'
import Storage from '../helpers/Storage'
import NotFoundImage from '../images/image_not_found.png'
import Timeout from '../components/Timeout'
import '../css/Machines.css'

const NOTHING = -2
const READY = "READY"
const SUCCESS = "SUCCESS"
const ERROR = "ERROR"
const CANCELED = "CANCELED"
const BUSY = "BUSY"
const CONNECTING = "CONNECTING"
const FAILED = "FAILED"
const INSUFICIENT_MIN_FUNDS = "INSUFICIENT_MIN_FUNDS"
const NOT_COMPANY_FUNDS_ASSIGNED = "NOT_COMPANY_FUNDS_ASSIGNED"
const NETWORK_ERROR = 10

/**
 * Keyboard types -
 */
const DISABLED = "DISABLED"
const PRODUCT = "PRODUCT"
const KEYPAD = "KEYPAD"

const MachineScreen = withRouter(({history, onBalance}) => {
  const [error, setError] = useState(false)
  const [isProductsRequesting, setProductsRequesting] = useState(false)
  const [isConnecting, setIsConnecting] = useState(false)
  const [isCanceling, setIsCanceling] = useState(false)
  const [noBalance, setNoBalance] = useState(false)
  const [balance, setBalance] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [sessionId, setSessionId] = useState(null)
  const [funds, setFunds] = useState(null)
  const [bonusFunds, setBonusFunds] = useState(null)
  const [companyFunds, setCompanyFunds] = useState(null)
  const [cards, setCards] = useState([])
  const [status, setStatus] = useState(NOTHING)
  const [statusDetails, setStatusDetails] = useState(null)
  const [products, setProducts] = useState(null)
  const [product, setProduct] = useState(null)
  const [fetching, setFetching] = useState(true)
  const [loading, setLoading] = useState(false)
  const [imageLoading, setImageLoading] = useState(false)
  const [machinesCustomization, setMachinesCustomization] = useState(null)
  const [machineImageStatus, setMachineImageStatus] = useState(MachineAnimated)
  const [keypadType, setKeypadType] = useState(null)
  const [keypadConfig, setKeypadConfig] = useState(null)
  const [pressedKeys, setPressedKeys] = useState(null)
  const [pressedKeysCode, setPressedKeysCode] = useState(null)
  const [productQuantity, setProductQuantity] = useState(1)
  const [selectedProduct, setSelectedProduct] = useState(false)

  const payrollId = useRef(null)
  const formData = useRef(new FormData())
  const timer = useRef(null)
  const machine = useRef(history.location.state.machine)

  const defaultMachine = Storage.get("default_machine")
  const company = Storage.get("subdomain")
  const purchase_enabled = (Storage.get("purchase_enabled") === 'true');
  const productControl = (Storage.get("product_control") === 'true');
  const profile = Storage.getProfile()
  const bynt_fingerprint = Storage.get("bynt_token")

  //const keypad = '[{ "button_name": "1", "button_selection_code": "[0,0]"},{ "button_name": "2", "button_selection_code": "[1,0]"},{ "button_name": "3", "button_selection_code": "[2,0]"},{ "button_name": "4", "button_selection_code": "[0,1]"},{ "button_name": "5", "button_selection_code": "[1,1]"},{ "button_name": "7", "button_selection_code": "[0,2]"},{ "button_name": "8", "button_selection_code": "[1,2]"},{ "button_name": "6", "button_selection_code": "[2,1]"},{ "button_name": "9", "button_selection_code": "[2,2]"},{ "button_name": "*", "button_selection_code": "[0,3]"},{ "button_name": "0", "button_selection_code": "[1,3]"},{ "button_name": "#", "button_selection_code": "[2,3]"}]'
  //const keypad = '[\r\n { \"button_name\": \"1\", \"button_selection_code\": \"[0,0]\"},\r\n { \"button_name\": \"2\", \"button_selection_code\": \"[1,0]\"},\r\n { \"button_name\": \"3\", \"button_selection_code\": \"[2,0]\"},\r\n { \"button_name\": \"4\", \"button_selection_code\": \"[0,1]\"},\r\n { \"button_name\": \"5\", \"button_selection_code\": \"[1,1]\"},\r\n { \"button_name\": \"6\", \"button_selection_code\": \"[2,1]\"},\r\n { \"button_name\": \"7\", \"button_selection_code\": \"[0,2]\"},\r\n { \"button_name\": \"8\", \"button_selection_code\": \"[1,2]\"},\r\n { \"button_name\": \"9\", \"button_selection_code\": \"[2,2]\"},\r\n { \"button_name\": \"*\", \"button_selection_code\": \"[0,3]\"},\r\n { \"button_name\": \"0\", \"button_selection_code\": \"[1,3]\"},\r\n { \"button_name\": \"#\", \"button_selection_code\": \"[2,3]\"}\r\n]'

  useEffect(() => {
    /* 1. handless_enabled: true / false
    2. handless_selection_type: PRODUCT / KEYPAD
    3. keyboard_config: [JSON] */
    /* setKeypadType(KEYPAD)
    setKeypadConfig(JSON.parse(keypad)) */
    Request.get('/company/'+ machine.current.id_company +'/machine/'+ machine.current.id_machine +'/handless_config', null).then((data) => {
      if(data.handless_enabled) {
        if(data.handless_selection_type === PRODUCT) {
          setKeypadType(PRODUCT)
        } else if (data.handless_selection_type === KEYPAD) {
          setKeypadType(KEYPAD)
          setKeypadConfig(JSON.parse(data.keyboard_config))
        } else {
          setKeypadType(DISABLED)
        }
      } else {
        setKeypadType(DISABLED)
      }
    }).catch((error) => {

    })
  }, [])

  const closeSession = useCallback(() => {
    Storage.setCredentials({})
    history.push('/')
  }, [history])

  const connectMachine = () => {
    setIsConnecting(true)
    var paymentMethod = formData.current.data("paymentMethod")
    let paymentType;
    if(paymentMethod === "-1") {
      paymentType = "FUNDS"
    } else if (paymentMethod === "-2") {
      paymentType = "BONUS_FUNDS"
    } else if (paymentMethod === "-3") {
      paymentType = "COMPANY_FUNDS"
    } else {
      paymentType = "CARD"
    }
    var params = {
      id_machine: machine.current.id_machine,
      machine_name: machine.current.machine_name,
      payment_method: paymentMethod === "-1" ? payrollId.current : paymentMethod,
      payment_type: paymentType,
      id_company: machine.current.id_company,
      bynt_fingerprint: bynt_fingerprint
    }
    setMachineImageStatus(MachineAnimated)
    Request.post('/user/begin_session', params).then((data) => {
      setSessionId(data.id_session)
      setIsCanceling(false)
      setStatus(CONNECTING)
    }).catch((error) => {
      setMachineImageStatus(MachineImage)
      if (error.code === "INSUFICIENT_MIN_FUNDS") {
        setStatus(INSUFICIENT_MIN_FUNDS)
      } else if (error.code === "NOT_COMPANY_FUNDS_ASSIGNED") {
        setStatus(NOT_COMPANY_FUNDS_ASSIGNED)
      } else {
        setStatus(FAILED)
      }
    })
  }

  const cancelConnect = () => {
    setMachineImageStatus(MachineAnimated)
    setIsCanceling(true)
    var params = {
      id_session: sessionId
    }
    Request.post('/session_cancel', params).then((data) => {
      clearValue()
    }).catch((error) => {
      clearValue()
    })
  }

  const setProductDefaultImage = (event) => {
    event.target.src = NotFoundImage
    setImageLoading(false)
  }

  const setProductView = (product) => {
    setImageLoading(true)
    setProduct(product)
  }

  const checkSession = useCallback(() => {
    Request.get('/get_session/' + sessionId, null).then((data) => {
      setStatusDetails(data.details)
      setMachineImageStatus(MachineImage)
      if (data.status === READY) {
        setStatus(data.status)
      } else if (data.status === SUCCESS) {
        setSessionId(null)
        setStatus(data.status)
      } else if (data.status === ERROR) {
        switch (data.details) {
          case "PROCESS_DENIED_CARD":
            setErrorMessage("Ha ocurrido un error con tu tarjeta, contacta al banco emisor.")
            break
          case "PROCESS_DENIED_FUNDS":
            setErrorMessage("No se ha podido realizar la compra con saldo.")
            break
          case "PROCESS_INSUFICIENT_FUNDS":
            setErrorMessage("No cuentas con el saldo suficiente para esta compra.")
            break
          case "PROCESS_VEND_FAILURE":
            setErrorMessage("Ha ocurrido un error al entregar tu producto, se ha rembolzado el costo a tú saldo.")
            break
          default:
            setErrorMessage("Ha ocurrido un error, intentalo de nuevo mas tarde.")
        }
        setSessionId(null)
        setStatus(data.status)
      } else if (data.status === CANCELED) {
        setSessionId(null)
        setStatus(null)
        setIsConnecting(false)
        if(defaultMachine !== null) {
          Storage.setCredentials({})
          history.push('/')
        }
      } else if (data.status === BUSY) {
        setSessionId(null)
        setStatus(data.status)
      }
    }).catch((error) => {
      if (error.code === 'AUTHORIZATION_ERROR') {
        closeSession()
      } else {
        setStatus(NETWORK_ERROR)
      }
    })
  }, [sessionId, closeSession])

  const getFunds = useCallback(() => {
    setFetching(true)
    Request.get('/profile', null).then((data) => {
      setFetching(false)
      setFunds(data.funds / 100)
      setBonusFunds(data.bonus_funds / 100)
      setCompanyFunds(data.company_funds / 100)
    }).catch((error) => {
      setFetching(false)
    })
  }, [])

  const resendEmail = useCallback(() => {
    setFetching(true)
    Request.get('/register/resend_email', null).then((data) => {
      setFetching(false)
      setSuccessMessage("Se ha enviado un enlace de activación de cuenta a tu correo")
    }).catch((error) => {
      setFetching(false)
      setErrorMessage("Ha ocurrido un error, intenta de nuevo más tarde.")
    })
  }, [])

  const createReport = () => {
    history.push('/report-machine', { machine : machine.current })
  }

  const addButtons = useCallback((product) => {
    let new_state
    let new_state_code
    if(pressedKeys !== null) {
      new_state = pressedKeys + product.button_name
      new_state_code = pressedKeysCode
      new_state_code.push(JSON.parse(product.button_selection_code))
    } else {
      new_state = product.button_name
      new_state_code = JSON.parse(`[${product.button_selection_code}]`)
    }
    setPressedKeys(new_state)
    setPressedKeysCode(new_state_code)
  }, [pressedKeys])

  const sendKeypadButtons = useCallback(() => {
    setFetching(true)
    var params = {
      id_session: sessionId,
      buttons: pressedKeysCode,
      id_company: machine.current.id_company,
      id_machine: machine.current.id_machine
    }
    Request.post('/handless/product_selected', params).then((data) => {
    }).catch((error) => {
      setIsCanceling(false)
    })
  }, [sessionId, pressedKeys])

  const sendProductButton = useCallback((product) => {
    setFetching(true)
    var params = {
      id_session: sessionId,
      buttons: product.button_selection_code,
      id_company: machine.current.id_company,
      id_machine: machine.current.id_machine
    }
    Request.post('/handless/product_selected', params).then((data) => {
    }).catch((error) => {
      setIsCanceling(false)
    })
  }, [sessionId, pressedKeys])
  
  useEffect(() => {
    getFunds()
  }, [getFunds, funds, bonusFunds, companyFunds])
  
  useEffect(() => {
    setLoading(true)
    Request.get('/get_methods', null).then((data) => {
      var cards = data.map((card) => {
        return [card.id_card, card.maskedPAN]
      })
      if(defaultMachine !== null) {
        cards.push([-1, "Usar saldo - $" + (funds)])
      } else {
        cards.unshift([-1, "Usar saldo - $" + (funds)])
      }
      
      if(bonusFunds !== 0) {
        cards.unshift([-2, "Usar creditos - $" + (bonusFunds)])
      }

      if(companyFunds !== 0 && !isNaN(companyFunds) && machine.current.id_company === profile.id_company) {
        cards.unshift([-3, "Usar saldo de compañia - $" + (companyFunds)])
      }
      
      if(machinesCustomization !== null){
        if(machinesCustomization.allowed_payment_method) {
          switch (machinesCustomization.allowed_payment_method) {
            case "company_funds":
              cards = []
              cards.unshift([-3, "Usar saldo de compañia - $" + (isNaN(companyFunds) ? 0 : companyFunds)])
              break
            default:
              //Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
              break
          }
        }
      }
      setCards(cards)
      setLoading(false)
    }).catch((error) => {
      //TODO: error
      setLoading(false)
    })
  }, [funds, bonusFunds, companyFunds, machinesCustomization])

  useEffect(() => {
    /* let data = '[{"id_product":10,"position":"1","friendly_name":"Cappuccino Original","description":"Cappuccino Original","price":"0.00","existence":0,"button_selection_code":"[[0,0]]"},{"id_product":20,"position":"2","friendly_name":"Latte","description":"Latte","price":"1.00","existence":0,"button_selection_code":"[[0,1]]"},{"id_product":21,"position":"3","friendly_name":"Cappuccino Vainilla","description":"Cappuccino Vainilla","price":"1.00","existence":0,"button_selection_code":"[[0,2]]"},{"id_product":22,"position":"4","friendly_name":"Latte Vainilla","description":"Latte Vainilla","price":"1.00","existence":0,"button_selection_code":"[[0,3]"},{"id_product":23,"position":"5","friendly_name":"Chocolate abuelita","description":"Chocolate abuelita","price":"1.00","existence":0,"button_selection_code":"[[0,4]]"},{"id_product":24,"position":"6","friendly_name":"Carlos V","description":"Carlos V","price":"1.00","existence":0,"button_selection_code":"[[0,5]]"},{"id_product":25,"position":"7","friendly_name":"Mokaccino","description":"Mokaccino","price":"1.00","existence":0,"button_selection_code":"[[0,6]]"},{"id_product":26,"position":"8","friendly_name":"Americano","description":"Caf\u00e9 Americano","price":"1.00","existence":0,"button_selection_code":"[[0,7]]"}]'
    data = JSON.parse(data)
    let items = data.map((item) => {
      let product_name = item.friendly_name !== null ? item.friendly_name : item.name
      return {
        "id_product": item.id_product,
        "name": product_name,
        "description": item.description,
        "price": item.price,
        "existence": item.existence,
        "position": item.position,
        "image": `https://vct-cloud-images.s3.amazonaws.com/products/grupobiz/` + item.id_product + ".jpg",
        "button_selection_code": item.button_selection_code
      }
    })
    setProducts(items) */

    setProductsRequesting(true)
    Request.get(`/company/${machine.current.id_company}/machine/${machine.current.id_machine}/products`, null).then((data) => {
      setProductsRequesting(false)
      let items = data.map((item) => {
        let product_name = item.friendly_name !== null ? item.friendly_name : item.name
        return {
          "id_product": item.id_product,
          "name": product_name,
          "description": item.description,
          "price": item.price,
          "existence": item.existence,
          "position": item.position,
          "image": `https://vct-cloud-images.s3.amazonaws.com/products/${machine.current.subdomain}/` + item.id_product + ".jpg",
          "button_selection_code": item.button_selection_code
        }
      })
      setProducts(items)
    }).catch((error) => {
      if (error.code === 'AUTHORIZATION_ERROR') {
        closeSession()
      } else {
        setProductsRequesting(false)
      }
    })
  }, [machine, closeSession, company])

  useEffect(() => {
    if (balance === 0) setNoBalance(true)
  }, [balance])

  useEffect(() => {
    //setKeypadType(machine.current.keypad_type)
  }, [keypadType])

  useEffect(() => {
    if (sessionId === null) {
      if (timer.current !== null) clearInterval(timer.current)
      timer.current = null
    } else {
      if (timer.current !== null) clearInterval(timer.current)
      timer.current = setInterval(checkSession, 5000)
    }
  }, [sessionId, checkSession])

  useEffect(() => {
    Request.get(`/get_customization/${machine.current.subdomain}`, null).then((data) => {
      if(data.app_customization !== "") {
        setMachinesCustomization(JSON.parse(data.app_customization))
      }
    }).catch((error) => {
      // Error
    })
  }, [machine])

  const onChange = (text) => {
  }

  const clearValue = () => {
    setPressedKeys(null)
    setPressedKeysCode(null)
  }

  const handleDeduct = () => {
    if (productQuantity !== 1) setProductQuantity(productQuantity - 1)
  }

  const handleAdd = () => {
    setProductQuantity(productQuantity + 1)
  }

  const confirmProductControlVend = (product) => {
    setSelectedProduct(product)
  }

  const startProductControlVend = useCallback(() => {
    setFetching(true)
    var params = {
      id_company: machine.current.id_company,
      id_machine: machine.current.id_machine,
      position: selectedProduct.position,
      quantity: productQuantity
    }
    Request.post('/machines/product_control/vend', params).then((data) => {
      //TODO: Show success message
    }).catch((error) => {
      setErrorMessage("No se ha podido completar la petición, intente de nuevo más tarde.")
    })
  }, [selectedProduct])

  return (
    <div>
      <Header rightButton={defaultMachine !== null ? 'ADD_CARD' : 'NONE'} secondaryButton={defaultMachine !== null ? 'CLOSE_SESSION' : 'CANCEL'} />
      <div className="machine-summary">
        <img src={MachineImage} alt="Máquina Expendedora" />      
        <div className="machine-data">
          <h1>{machine.current.machine_name}</h1>
          <div className="machine-address">{machine.current.machine_address}</div>
        </div>
      </div>
      { profile.verified &&
        <Link onClick={createReport} to={null} className='link center'>Reportar máquina</Link>
      }
      <br></br>
      { machine.current.distance >= 1000 &&
        <div className="icon-info distance-alert"> La máquina se encuentra muy lejos de tu ubicación.</div>
      }

      { productControl && products &&
        <div className="">
          <div className="machine-connecting select-message">Selecciona la cantidad de producto a dispensar: </div>
          <div className="select-buttons">
            <button className='primary operators' onClick={handleDeduct} disabled={isConnecting}>-</button>
            <input 
              className="operators-text"
              value={productQuantity}
              type="text" 
              onChange={onChange} 
              onBlur={null}
              readOnly />
            <button className='primary operators' onClick={handleAdd} disabled={isConnecting}>+</button>
          </div>
          <div className="machine-connecting select-product-control-message">¡Selecciona tu producto!</div>
          <div className="select-buttons product-control-buttons">
          { products.map((product) => {
            return (
              <div key={ product.id_product } className={"product-mini-card mini-card-clickable"} onClick={() => confirmProductControlVend(product)}>
                <div className="small-image"><img src={ product.image } onError={(event) => { event.target.src = NotFoundImage }} alt={product.name} /></div>
                <div className="product-data">
                  <div className="product-name">{ product.name }</div>
                </div>
              </div>
            )})
          }
          </div>
        </div>
      }

      { loading && !productControl &&
        <div className="machine-search">
          <i className="icon-spin icon-size-medium spin"></i>
          <div>Cargando métodos de pago...</div>
        </div>
      }

      { purchase_enabled && profile.verified &&
        <div>
          <div className="fields-container">
            { !fetching && !productControl &&
              <Select 
                name="paymentMethod" 
                label="Selecciona método de pago" 
                options={cards} 
                onData={formData.current.onData()} />
            }
          </div>
            <div className='buttons'>
              {!productControl &&
                <button className='primary' onClick={connectMachine} disabled={isConnecting || (noBalance && formData.current.data("paymentMethod") === -1)}>Conectar</button>
              }
            </div>
        </div>
      }

      { !profile.verified &&
        <div className="confirmation-required">Para poder realizar una compra, primero tienes que confirmar tu correo electrónico.
          <br></br>
          <br></br>
          <button className="primary" onClick={() => resendEmail()}>Reenviar correo</button>
        </div>
      }

      { defaultMachine !== null &&
        <div className='banner red'>
            <Timeout minutes={2} pause={isConnecting} onTimeout={() => { closeSession() }}>Desconexión en</Timeout>
        </div>
      }
      { status === CONNECTING &&
        <div className='banner red hidden'>
            <Timeout seconds={30} onTimeout={() => { cancelConnect() }}>Desconexión en</Timeout>
        </div>
      }
      { isConnecting &&
        <Modal>
          <div className="machine-summary flex-wrap">
            <img src={machineImageStatus} alt="Máquina Expendedora" className="no-margin" />
            <div className="break"></div>
            <div className="items-container">
              <h2>{machine.current.name}</h2>
              { status === CONNECTING && !isCanceling &&
                <div className="machine-connecting">Conectando...</div>
              }
              { status === READY && statusDetails === null &&
                <div className='banner red'>
                  <Timeout seconds={45} showOnLast={15} pause={status === READY && statusDetails !== null} onTimeout={() => { cancelConnect() }}>Desconexión en</Timeout>
                </div>
              }
              { status === READY && statusDetails !== null &&
                <h3>
                  Vendiendo
                </h3>
              }
              { status === READY && !isCanceling && (keypadType === DISABLED || keypadType === null) &&
                <div>
                  <div className="machine-connecting select-message">¡Selecciona tu producto en la máquina expendedora!</div>
                </div>
              }
              { status === READY && !isCanceling && keypadType === PRODUCT &&
                <div>
                  <div className="machine-connecting select-message">¡Selecciona tu producto en el teclado!</div>
                  { products && (products.length > 0) && 
                    <div className="select-buttons">
                      { products.map((product) => {
                        return (
                          <div key={ product.id_product } className={"product-mini-card " + (product.button_selection_code !== "" ? "mini-card-clickable" : "mini-card-unclickable")} onClick={() => sendProductButton(product)}>
                            <div className="small-image"><img src={ product.image } onError={(event) => { event.target.src = NotFoundImage }} alt={product.name} /></div>
                            <div className="product-data">
                              <div className="product-name">{ product.name }</div>
                            </div>
                          </div>
                        )})
                      }
                    </div>
                  }
                </div>
              }
              { status === READY && !isCanceling && keypadType === KEYPAD &&
                <div>
                  <div className="machine-connecting select-message">¡Selecciona tu producto en el teclado!</div>
                  <div className="machine-keypad-input">
                    <input 
                      value={pressedKeys === null ? "" : pressedKeys}
                      type="text" 
                      onChange={onChange} 
                      onBlur={null}
                      readOnly />
                    <span className="icon-cancel-circled" onClick={() => clearValue()}></span>
                  </div>
                  <button className="primary machine-keypad-send" onClick={() => sendKeypadButtons()}>Enviar</button>
                  <div className="select-buttons-limited">
                    { keypadConfig.map((button) => {
                      return (
                        <div key={ button.button_name } className={"product-keypad-mini-card " + (button.button_selection_code !== "" ? "mini-card-clickable" : "mini-card-unclickable")} onClick={() => addButtons(button)}>
                          <h1>{button.button_name}</h1>
                        </div>
                      )})
                    }
                  </div>
                </div>
              }
              { status === CONNECTING && isCanceling &&
                <div className="machine-connecting">Cancelando...</div>
              }
              { status === READY && isCanceling &&
                <div className="machine-connecting">Cancelando...</div>
              }
              { status === SUCCESS &&
                <div className="icon-ok-circled machine-success">¡Gracias por tu compra!</div>
              }
              { status === ERROR &&
                <div className="icon-canceled-circled machine-success">{errorMessage}</div>
              }
              { status === BUSY &&
                <div className="icon-canceled-circled machine-success">La máquina se encuentra ocupada. Intenta de nuevo más tarde.</div>
              }
              { status === NETWORK_ERROR &&
                <div className="icon-spin spin machine-connecting">Error de red. Reconectando...</div>
              }
              { status === FAILED &&
                <div className="icon-canceled-circled machine-connecting">No se ha podido iniciar la sesión.</div>
              }
              { status === INSUFICIENT_MIN_FUNDS &&
                <div className="icon-canceled-circled machine-connecting">No cuentas con el saldo minimo necesario para realizar esta compra.</div>
              }
              { status === NOT_COMPANY_FUNDS_ASSIGNED &&
                <div className="icon-canceled-circled machine-connecting">No puedes iniciar una sesión con esta máquina.</div>
              }
            </div>
          </div>
          <div className="buttons">
            { [CONNECTING, READY, NETWORK_ERROR].includes(status) && 
              <button className="secondary" onClick={() => cancelConnect()}>Cancelar</button>
            }
            { [SUCCESS, FAILED, INSUFICIENT_MIN_FUNDS, NOT_COMPANY_FUNDS_ASSIGNED].includes(status) &&
              <button className="primary" onClick={() => history.go(-1)}>Aceptar</button>
            }
            { [ERROR, BUSY].includes(status) &&
              <button className="primary" onClick={() => setIsConnecting(false)}>Aceptar</button>
            }
          </div>
        </Modal>
      }
      { noBalance && formData.current.data("paymentMethod") === -1 &&
        <Modal>
          <div className="error-modal">
            <i className="icon-credit-card blue"></i>
            <div>Para poder realizar una compra, primero tienes que agregar crédito a tu cuenta.</div>
          </div>
          <div className="buttons">
            <button className="secondary" onClick={() => setNoBalance(false)}>Cancelar</button>
            <button className="primary" onClick={() => history.push('/add-funds')}>Aceptar</button>
          </div>
        </Modal>    
      }
      { selectedProduct &&
        <Modal>
          <div className="error-modal">
            <i className="icon-info blue"></i>
            <div>Confirma la peticion. <br/> Producto: <b>{selectedProduct.name}</b> <br/> Cantidad: <b>{productQuantity}</b></div>
          </div>
          <div className="buttons">
            <button className="secondary" onClick={() => setSelectedProduct(false)}>Cancelar</button>
            <button className="primary" onClick={() => startProductControlVend()}>Aceptar</button>
          </div>
        </Modal>
      }
      { (isProductsRequesting === null) &&
        <div className="icon-spin spin machine-connecting">Obteniendo productos...</div>
      }
      { products && (products.length > 0) && !productControl &&
        <div className="product-list">
        { products.map((product) => {
            return (
              <div key={ product.id_product } className="product-card" onClick={() => setProductView(product)}>
                <div className="image"><img src={ product.image } onError={(event) => { event.target.src = NotFoundImage }} alt={product.name} /></div>
                <div className="product-data">
                  <div className="product-name">{ product.name }</div>
                  <div className="product-price">${ product.price }</div>
                  <div className="product-position">Posicion: {product.position}</div>
                </div>
              </div>
            )
          })
        }
        </div>
      }
      { product &&
        <Modal>
          <div className="product-view" onClick={() => setProduct(null)}>
            { imageLoading && <div className="icon-spin spin"></div> }
            <div className={classNames("product-data", { 'hidden': imageLoading})}>
              <img src={ product.image } onError={(event) => { setProductDefaultImage(event) }} onLoad={() => { setImageLoading(false) }} alt={product.name} />
              <div className="product-name">{ product.name }</div>
              <div className="product-price">Precio: ${ product.price }</div>
              <div className="product-position">Posicion: {product.position}</div>
              <div className="product-description">{ product.description }</div>
            </div>
            <div className='buttons'>
              <button className='secondary' onClick={() => setProduct(false)}>Volver</button>
            </div>
          </div>
        </Modal>    
      }
      { error &&
        <Modal>
          <div className="error-modal">
            <i className="icon-attention orange"></i>
            <div>{error}</div>
          </div>
          <div className="buttons">
            <button className="primary" onClick={() => setError(false)}>Aceptar</button>
          </div>
        </Modal>
      }
      { successMessage &&
        <Alert onClick={() => { history.go(-1)}}>
          <span className='icon-credit-card icon-size-large icon-color-green'></span>
          <div style={{display: "inline", width: "100%"}}>
            {successMessage}
          </div>
        </Alert>
      }
    </div>
  )
})

export default MachineScreen