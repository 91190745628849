import React, { useState, useRef, useEffect } from 'react'
import Request from '../helpers/Request'
import { Link, withRouter } from 'react-router-dom'
import Storage from '../helpers/Storage'
import FormData from '../helpers/FormData'
import Field from '../components/Field'
import Alert from '../components/Alert';
import LogoImage from '../images/logo.png'
import '../css/Login.css'

const RegisterScreen = withRouter(({ history }) => {
  const [successfulRegister, setSucessfulRegister] = useState(false)
  const [error, setError] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const [customization, setCustomization] = useState(false)
  const [tabletVersion, setTabletVersion] = useState(false)
  const formData = useRef(new FormData())

  console.log("CUSTOM: ",customization)

  const errorMessages = {
    "EMAIL_ALREADY_EXISTS": "El correo ya está registrado. Revise que el correo esté bien escrito o recupere su contrasña",
  }

  const nameValidate = (data) => {
    if (/^.{0,2}$/i.test(data)) {
      return 'Debe de tener al menos 3 caracteres'
    }
    return false
  }

  const phoneValidate = (data) => {
    if (data === "") {
      return false
    } else if (!/^(\+\d{1,3})?[\s]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/i.test(data)) {
      return 'No tiene el formato correcto'
    }
    return false
  }

  const emailValidate = (data) => {
    if (!/^[ ]*[a-z0-9_.-]+@[a-z0-9_.-]+[ ]*$/i.test(data)) {
      return 'No tiene el formato correcto'
    }
    return false
  }

  const passwordValidate = (data) => {
    if (/^.{0,7}$/i.test(data)) {
      return 'Debe de tener al menos 8 caracteres'
    }
    return false
  }

  useEffect(() => {
    const defaultMachine = Storage.get('default_machine')
    if(defaultMachine !== null) {
      setTabletVersion(true)
    }
    setCustomization(Storage.getCustomization())
  }, [])

  const createAccount = () => {
    if (formData.current.allValid()) {
      setIsRequesting(true)
      var data = formData.current.data()
      data.email = data.email.trim().toLowerCase()
      Request.post('/register', data).then((data) => {
        Storage.setCredentials(data)
        setIsRequesting(false)
        setSucessfulRegister(true)
      }).catch((error) => {
        setIsRequesting(false)
        setSucessfulRegister(false)
        if (error.code === "EMAIL_ALREADY_EXISTS") {
          setError(errorMessages[error.code])
        } else {
          setError(error.message)
        }
      })
    }
  }

  const onFinishRegister = () => {
    history.push('/machines')
  }

  return (
    <div>
      <div className='title'>
        <h1>Crear cuenta</h1>
        <div className="title-logo-container">
          <img src={typeof customization['pwa_logo'] !== 'undefined' ? customization['pwa_logo'] : LogoImage} alt='' />
        </div>
      </div>
      <div className='fields-container'>
        <form>
          <Field 
            name='name' 
            label='Nombre completo'
            placeholder='ej. tu nombre completo'
            autoComplete='name'
            onValidate={nameValidate} 
            onData={formData.current.onData()} />
          <Field 
            name='phone' 
            label='Número de teléfono'
            placeholder='Número de teléfono opcional'
            onValidate={phoneValidate}
            onData={formData.current.onData()} />
          <Field 
            name='email' 
            label='Correo' 
            placeholder='ej. tunombre@tucorreo.com' 
            autoComplete='email'
            autoCapitalize='off'
            onValidate={emailValidate}
            onData={formData.current.onData()} />
          <Field 
            name='password' 
            label='Clave'
            type='password' 
            placeholder='Contraseña nueva'
            autoComplete='current-password'
            onValidate={passwordValidate}
            onData={formData.current.onData()} />
        </form>
      </div>
      <div className='buttons'>
        <button className='primary' onClick={createAccount} disabled={isRequesting}>Crear cuenta</button>
      </div>
      <div className='center-padding'>
        <span>¿Ya tienes cuenta? </span><Link to='/login' className='link'>INICIAR SESIÓN</Link>
      </div>
      { successfulRegister && 
      <Alert onClick={onFinishRegister}>
        <span className='icon-mail icon-size-large icon-color-gray'></span>
        Por favor revisa tu correo y confirma tu cuenta para poder realizar compras.
      </Alert>
      }
      { error &&
      <Alert onClick={() => { setError(false) }}>
        <span className='icon-error icon-size-large icon-color-red'></span>
        {error}
      </Alert>
      }
    </div>
  )
})

export default RegisterScreen