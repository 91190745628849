import React, { useEffect, useState, useRef } from 'react'
import { Link, withRouter } from 'react-router-dom'
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import Request from '../helpers/Request'
import Storage from '../helpers/Storage'
import FormData from '../helpers/FormData'
import Field from '../components/Field'
import Alert from '../components/Alert'
import LogoImage from '../images/logo.png'
import '../css/Login.css'

const LoginScreen = withRouter(({ history }) => {
  const [error, setError] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const [customization, setCustomization] = useState(false)
  const [tabletVersion, setTabletVersion] = useState(false)
  const formData = useRef(new FormData())

  const emailValidate = (data) => {
    if (!/^[ ]*[a-z0-9_.-]+@[a-z0-9_.-]+[ ]*$/i.test(data)) {
      return 'No tiene el formato correcto'
    }
    return false
  }

  const passwordValidate = (data) => {
    if (/^.{0,5}$/i.test(data)) {
      return 'Debe de tener al menos 6 caracteres'
    }
    return false
  }

  useEffect(() => {
    const defaultMachine = Storage.get('default_machine')
    if(defaultMachine !== null) {
      setTabletVersion(true)
    }
    setCustomization(Storage.getCustomization())
  }, [])

  const login = () => {
    if (formData.current.allValid()) {
      setIsRequesting(true)
      var data = formData.current.data()
      data.email = data.email.trim()
      // Oauth2.0 - Resource Owner Password Credentials Grant
      Request.post('/login', data).then((data) => {
        Storage.setCredentials(data)
        setIsRequesting(false)
        history.push('/machines')
      }).catch((error) => {
        if(error.code === "VALIDATION_ERROR") {
          setError("Revisa tu información e intenta de nuevo.");
        } else if(error.code === "WRONG_CREDENTIALS") {
          setError("Email/Contraseña incorrectos, intenta de nuevo.");
        } else if(error.code === "USER_NOT_FOUND") {
          setError("Email/Contraseña incorrectos, intenta de nuevo.");
        } else {
          setError(error.message);
        }
        setIsRequesting(false)
      })
    }
  }

  const responseFacebook = (response) => {
    setIsRequesting(true)
    if (response.hasOwnProperty('accessToken')){
      Request.post('/login/facebook', response).then((data) => {
        Storage.setCredentials(data)
        setIsRequesting(false)
        history.push('/machines')
      }).catch((error) => {
        if(error.code === "VALIDATION_ERROR") {
          setError("Revisa tu información e intenta de nuevo.");
        } else if(error.code === "WRONG_CREDENTIALS") {
          setError("Email/Contraseña incorrectos, intenta de nuevo.");
        } else if(error.code === "USER_NOT_FOUND") {
          setError("Email/Contraseña incorrectos, intenta de nuevo.");
        } else {
          setError(error.message);
        }
      }) 
    } else if(response.hasOwnProperty("status") && response.status === "unknown") {
      setError("No se ha completado el inicio de sesión.");
    } else {
      setError("Ha ocurrido un error, intenta de nuevo más tarde.");
    }

    setIsRequesting(false)
  }

  const responseGoogle = (response) => {
    setIsRequesting(true)
    if (response.hasOwnProperty('accessToken')){
      Request.post('/login/google', response).then((data) => {
        Storage.setCredentials(data)
        setIsRequesting(false)
        history.push('/machines')
      }).catch((error) => {
        if(error.code === "VALIDATION_ERROR") {
          setError("Revisa tu información e intenta de nuevo.");
        } else if(error.code === "WRONG_CREDENTIALS") {
          setError("Email/Contraseña incorrectos, intenta de nuevo.");
        } else if(error.code === "USER_NOT_FOUND") {
          setError("Email/Contraseña incorrectos, intenta de nuevo.");
        } else {
          setError(error.message);
        }
      }) 
    } else if(response.hasOwnProperty("status") && response.status === "unknown") {
      setError("No se ha completado el inicio de sesión.");
    } else {
      setError("Ha ocurrido un error, intenta de nuevo más tarde.");
    }

    setIsRequesting(false)
  }

  return (
    <div>
      <div className='title'>
        <h1>Iniciar sesión</h1>
        <div className="title-logo-container">
          <img src={typeof customization['pwa_logo'] !== 'undefined' ? customization['pwa_logo'] : LogoImage} alt='' />
        </div>
      </div>
      <div className='fields-container'>
        <form>
          <Field 
            name='email' 
            label='Correo electrónico' 
            placeholder='ej. tunombre@email.com' 
            autoComplete='email'
            autoCapitalize='off'
            onValidate={emailValidate}
            onData={formData.current.onData()} />
          <Field 
            name='password' 
            label='Clave'
            type='password' 
            placeholder='¿La recuerdas?'
            autoComplete='current-password'
            onValidate={passwordValidate}
            onData={formData.current.onData()} />
        </form>
      </div>
      <div className='buttons'>
        <button className='primary' onClick={login} disabled={isRequesting}>Ingresar ahora</button>
      </div>
      <div className="buttons login-facebook">
        <FacebookLogin
          appId="566047644318903"
          buttonText="Inicia con Facebook"
          autoLoad={false}
          fields="name,email,picture"
          onClick={responseFacebook}
          callback={responseFacebook}
          cssClass='facebook-button icon-facebook'
          textButton={"Inicia con Facebook"}
          />
      </div>
      <div className="buttons">
        <GoogleLogin
          clientId="339906950747-4ksj6vfhgk0f2jgs5h5r3fe6intrc508.apps.googleusercontent.com"
          buttonText="Inicia con Google"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
          className={'google-button icon-google'}
          icon={''}
          />
      </div>
      <div className='center-padding'>
        <Link to='/forgot-password' className='link'>Olvidé mi contraseña</Link>
      </div>
      { tabletVersion &&
        <div className='center-padding'>
          <Link to='/quick-login' className='link'>Inicia sesión con tu código</Link>
        </div>
      }
      <div className='center-padding'>
        <span>¿No tienes cuenta? </span><Link to='/register' className='link'>CREAR CUENTA</Link>
      </div>
      { error &&
      <Alert onClick={() => { setError(false) }}>
        <span className='icon-error icon-size-large icon-color-red'></span>
        {error}
      </Alert>
      }
    </div>
  )
})

export default LoginScreen