import '../css/Login.css'

import React, { useRef, useState } from 'react'

import Alert from '../components/Alert'
import Field from '../components/Field'
import FormData from '../helpers/FormData'
import Header from './Header'
import { Link } from 'react-router-dom'
import Modal from '../components/Modal'
import Request from '../helpers/Request'
import Storage from '../helpers/Storage'

const ProfileScreen = ({ history }) => {
  const [error, setError] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const [isChangingPassword, setChangingPassword] = useState(false)
  const [isCreatingPassword, setCreatingPassword] = useState(false)
  const [isChangingPin, setChangingPin] = useState(false)
  const [isRequestingPassword, setRequestingPassword] = useState(false)
  const [successfulChange, setSuccessfulChange] = useState(false)
  const formData = useRef(new FormData({}))
  const passwordData = useRef(new FormData({}))
  const profile = Storage.getProfile()

  const nameValidate = (data) => {
    if (/^.{0,2}$/i.test(data)) {
      return 'Debe de tener al menos 3 caracteres'
    }
    return false
  }

  const emailValidate = (data) => {
    if (!/^[a-z0-9_.-]+@[a-z0-9_.-]+$/i.test(data)) {
      return 'No tiene el formato correcto'
    }
    return false
  }

  const passwordValidate = (data) => {
    if (/^.{0,5}$/i.test(data)) {
      return 'Debe de tener al menos 6 caracteres'
    }
    return false
  }

  const pinValidate = (data) => {
    if (!/^.{4,6}$/i.test(data)) {
      return 'Debe tener entre 4 y 6 caracteres'
    }
    return false
  }
  
  const saveProfile = () => {
    if (formData.current.allValid()) {
      setIsRequesting(true)
      Request.post('/change_profile', formData.current.data()).then((data) => {
        setIsRequesting(false)
        setRequestingPassword(false)
        setSuccessfulChange("Se actualizó el perfil exitosamente!")
      }).catch((error) => {
        setIsRequesting(false)
        if(error.code === "VALIDATION_ERROR") {
          setError("Contraseña incorrecta, intenta de nuevo.")
        } else {
          setError(error.message)
        }
      })
    }
  }

  const changePassword = () => {
    setIsRequesting(true)
    Request.post('/change_password', passwordData.current.data()).then((data) => {
      setIsRequesting(false)
      setChangingPassword(false)
      setSuccessfulChange("La clave se ha cambiado exitosamente!")
    }).catch((error) => {
      setIsRequesting(false)
      if (error.data && error.data.error_code === "wrong_password") {
        setError("Clave incorrecta, revisa los datos y vuelve a intentar.")
      } else {
        setError("Error desconocido, favor de contactar al administrador.")
      }
    }) 
  }

  const setPassword = () => {
    setIsRequesting(true)
    Request.post('/set_password', passwordData.current.data()).then((data) => {
      setIsRequesting(false)
      setChangingPassword(false)
      setSuccessfulChange("La contraseña se ha configurado correctamente!")
    }).catch((error) => {
      setIsRequesting(false)
      if (error && error.code === "DIFFERENT_PASSWORDS") {
        setError("Las contraseñas no coinciden.")
      } else if (error && error.code === "PASSWORD_ALREADY_CREATED"){
        setError("Ya has configurado una contraseña anteriormente.")
      } else {
        setError("Error desconocido, favor de contactar al administrador.")
      }
    }) 
  }

  const changePin = () => {
    setIsRequesting(true)
    Request.post('/change_pin', passwordData.current.data()).then((data) => {
      setIsRequesting(false)
      setChangingPin(false)
      setSuccessfulChange("El PIN ha cambiado exitosamente!")
    }).catch((error) => {
      setIsRequesting(false)
      if (error.data && error.data.error_code === "wrong_password") {
        setError("Contraseña incorrecta, revisa los datos y vuelve a intentar.")
      } else {
        setError("Error desconocido, favor de contactar al administrador.")
      }
    })
  }

  return (
    <div>
      <Header secondaryButton={'CANCEL'} />
      <div className="title">
        <h1>Mi perfil</h1>
      </div>
      <div className='fields-container'>
        <form>
          <Field 
            name='userId' 
            label='ID de usuario'
            initialValue={profile.id_user}
            disabled={true} />
          <Field 
            name='collectorCode' 
            label='Código de collector'
            initialValue={profile.collector_code}
            disabled={true} />
          <Field 
            name='name' 
            label='Nombre completo'
            placeholder='ej. tu nombre completo'
            initialValue={profile.name}
            autoComplete='name'
            onValidate={nameValidate} 
            onData={formData.current.onData()} />
          <Field 
            name='email' 
            label='Correo' 
            placeholder='ej. tunombre@tucorreo.com'
            initialValue={profile.email}
            autoComplete='email'
            autoCapitalize='off'
            onValidate={emailValidate}
            onData={formData.current.onData()} />
        </form>
      </div>
      <div className='buttons'>
        <button className='primary' onClick={() => setRequestingPassword(true)} disabled={isRequesting}>Guardar</button>
      </div>
      <div className='profile-functions'>
        <div className='center-padding'>
          { profile.password_created === 1 &&
            <Link onClick={() => setChangingPassword(true)} className='link'>Cambiar contraseña</Link>
          }
          { profile.password_created === 0 &&
            <Link onClick={() => setCreatingPassword(true)} className='link'>Crear contraseña</Link>
          }
        </div>
        <div className='center-padding'>
          <Link onClick={() => setChangingPin(true)} className='link'>Cambiar PIN</Link>
        </div>
      </div>
      { isChangingPassword &&
        <Modal>
          <h2>Cambiar contraseña</h2>
          <div className='fields-container'>
            <form>
              <Field 
                name='password' 
                label='Clave actual'
                type='password' 
                placeholder='¿La recuerdas?'
                autoComplete='current-password'
                onValidate={passwordValidate}
                onData={passwordData.current.onData()} />
              <Field 
                name='new_password' 
                label='Nueva clave'
                type='password' 
                autoComplete='new-password'
                onValidate={passwordValidate}
                onData={passwordData.current.onData()} />
            </form>
          </div>
          <div className='buttons'>
            <button className='' onClick={() => setChangingPassword(false)} disabled={isRequesting}>Cancelar</button>
            <button className='primary' onClick={changePassword} disabled={isRequesting}>Cambiar</button>
          </div>
        </Modal>
      }
      { isCreatingPassword &&
        <Modal>
          <h2>Crear contraseña</h2>
          <div className='fields-container'>
            <form>
              <Field 
                name='password' 
                label='Nueva contraseña'
                type='password' 
                placeholder=''
                autoComplete='current-password'
                onValidate={passwordValidate}
                onData={passwordData.current.onData()} />
              <Field 
                name='r_password' 
                label='Repetir contraseña'
                type='password' 
                autoComplete='r-password'
                onValidate={passwordValidate}
                onData={passwordData.current.onData()} />
            </form>
          </div>
          <div className='buttons'>
            <button className='' onClick={() => setCreatingPassword(false)} disabled={isRequesting}>Cancelar</button>
            <button className='primary' onClick={setPassword} disabled={isRequesting}>Cambiar</button>
          </div>
        </Modal>
      }
      { isChangingPin &&
        <Modal>
          <h2>Cambiar PIN</h2>
          <div className='fields-container'>
            <form>
              <Field 
                name='password' 
                label='Contraseña actual'
                type='password' 
                placeholder='¿La recuerdas?'
                onValidate={passwordValidate}
                onData={passwordData.current.onData()} />
              <Field 
                name='new_pin' 
                label='Nuevo PIN'
                type='password' 
                autoComplete='new-password'
                onValidate={pinValidate}
                onData={passwordData.current.onData()} />
            </form>
          </div>
          <div className='buttons'>
            <button className='' onClick={() => setChangingPin(false)} disabled={isRequesting}>Cancelar</button>
            <button className='primary' onClick={changePin} disabled={isRequesting}>Cambiar</button>
          </div>
        </Modal>
      }
      { isRequestingPassword &&
        <Modal>
          <h2>Actualización del perfil</h2>
          <p>Para realizar los cambios, ingrese su contraseña actual.</p>
          <div className='fields-container'>
            <form>
              <Field 
                name='password' 
                label='Clave actual'
                type='password' 
                placeholder='¿La recuerdas?'
                autoComplete='current-password'
                onValidate={passwordValidate}
                onData={formData.current.onData()} />
            </form>
          </div>
          <div className='buttons'>
            <button className='' onClick={() => setRequestingPassword(false)} disabled={isRequesting}>Cancelar</button>
            <button className='primary' onClick={saveProfile} disabled={isRequesting}>Aplicar</button>
          </div>
        </Modal>
      }
      { successfulChange &&
        <Alert onClick={() => { history.push('/machines') }}>
          <span className='icon-ok-circled icon-size-large icon-color-green'></span>
          {successfulChange}
        </Alert>
      }
      { error &&
        <Alert onClick={() => { setError(false) }}>
          <span className='icon-error icon-size-large icon-color-red'></span>
          {error}
        </Alert>
      }
    </div>
  )
}

export default ProfileScreen