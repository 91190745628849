import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Header from './Header'
import Request from '../helpers/Request'
import Storage from '../helpers/Storage'
import MachineImage from '../images/machine.png'
import '../css/Purchases.css'

const READY = "READY"
const SUCCESS = "SUCCESS"
const ERROR = "ERROR"
const CANCELED = "CANCELED"
const BUSY = "BUSY"
const CONNECTING = "CONNECTING"

const PurchasesScreen = withRouter(({ history }) => {
  const [purchases, setPurchases] = useState([])
  const [fetching, setFetching] = useState(false)

  const onData = (key, value) => {
    
  }

  const purchaseDetails = (purchase) => {
    history.push('/purchase', { purchase: purchase })
  }

  useEffect(() => {
    setFetching(true)
    Request.get('/user/purchases', null).then((data) => {
      setPurchases(data["purchases"])
      setFetching(false)
    }).catch((error) => {
      setFetching(false)
    })
  }, [])

  return (
    <div>
      <Header secondaryButton={'CANCEL'} />
      <div className="title-list">
        <h1>Tus compras</h1>
      </div>
      { fetching &&
        <div className="purchases-search">
          <i className="icon-spin spin"></i>
          <div>Cargando...</div>
        </div>
      }
      <div className="purchases-list">
        {!fetching && purchases.length == 0 &&
          <div className="empty-state">No se han encontrado compras</div>
        }
        { purchases && purchases.map((purchase) => {
          if(purchase.status !== CANCELED) {
            return (
              <div key={ purchase.id_purchase } className="purchase-card">
                <div className="purchase-data" onClick={() => purchaseDetails(purchase)}>
                  <div className="purchase-card_top">
                    <div className="purchase-date">{ purchase.date }</div>
                    <div className="purchase-price">${ purchase.product_price !== null ? purchase.product_price : '0.00' }</div>
                  </div>
                  <div className="purchase-card_top">
                    <div className="purchase-product">{ purchase.product_name }</div>
                    { purchase.status === ERROR &&
                      <div className="purchase-status">Error en compra</div>
                    }
                    { purchase.status === CANCELED &&
                      <div className="purchase-status">Cancelado</div>
                    }
                    { purchase.status === SUCCESS &&
                      <div className="purchase-status">Compra exitosa</div>
                    }
                  </div>
                </div>
              </div>
            )
          }
        }) }
      </div>
    </div>
  )
})

export default PurchasesScreen