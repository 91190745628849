import React, { useState, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import Header from './Header';
import Request from '../helpers/Request'
import FormData from '../helpers/FormData'
import Field from '../components/Field'
import Alert from '../components/Alert'
import LogoImage from '../images/logo.png'
import '../css/Login.css'

const ForgotPasswordScreen = withRouter(({ history }) => {
  const [error, setError] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const [recoverySuccess, setRecoverySuccess] = useState(false)
  const formData = useRef(new FormData())

  const emailValidate = (data) => {
    if (!/^[ ]*[a-z0-9_.-]+@[a-z0-9_.-]+[ ]*$/i.test(data)) {
      return 'No tiene el formato correcto'
    }
    return false
  }

  const recovery = () => {
    if (formData.current.allValid()) {
      setIsRequesting(true)
      var data = formData.current.data()
      data.email = data.email.trim()
      data.url = window.location.href
      Request.post('/recovery', data).then((response) => {
        setRecoverySuccess('Se ha enviado un correo a tu bandeja de entrada con instrucciones para recuparar la contraseña.')
      }).catch((error) => {
        setIsRequesting(false)
        setError('No fue posible encontrar tu cuenta, revisa si el correo esta bien escrito e intenta de nuevo.')
      })
    }
  }

  return (
    <div>
      <Header secondaryButton={'CANCEL'} />
      <div className='title'>
        <h1>Recuperar contraseña</h1>
        <img src={LogoImage} alt='Tasa de cafe' />
      </div>
      <div className='fields-container'>
        <form>
          <Field 
            name='email' 
            label='Correo electrónico' 
            placeholder='ej. tunombre@tucorreo.com' 
            autoComplete='email'
            autoCapitalize='off'
            onValidate={emailValidate}
            onData={formData.current.onData()} />
        </form>
      </div>
      <div className='buttons'>
        <button className='primary' onClick={recovery} disabled={isRequesting}>Enviar link</button>
      </div>
      { error &&
        <Alert onClick={() => { setError(false) }}>
          <span className='icon-error icon-size-large icon-color-red'></span>
          {error}
        </Alert>
      }
      { recoverySuccess &&
        <Alert onClick={() => { history.go(-1) }}>
          <span className='icon-mail icon-size-large icon-color-green'></span>
          {recoverySuccess}
        </Alert>
      }
    </div>
  )
})

export default ForgotPasswordScreen