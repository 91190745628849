import React, { useRef, useEffect, useCallback } from 'react';

const Select = ({name, label, options, initialValue, onData}) => {
  const select = useRef()
  const value = useRef(initialValue)

  const _onData = useCallback(onData, [])

  const handleChange = useCallback(() => {
    value.current = select.current.value
    _onData(name, value.current)
  }, [_onData, name])

  useEffect(() => {
    handleChange()
  }, [options, handleChange])

  return (
    <div className="field">
      <label>{label}</label>
      <div className="select">
        <select ref={select} onChange={handleChange}>
          { options.map((option) => {
              return <option key={ "option_" + name + '_' + option[0] } value={ option[0] } selected={ option[0] === value.current ? 'selected' : null }>{ option[1] }</option>
          })}
        </select>
      </div>
    </div>
  )
}

export default Select