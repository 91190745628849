import React, { useState, useEffect, useRef, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import Header from './Header';
import Select from '../components/Select';
import FormData from '../helpers/FormData'
import Request from '../helpers/Request'
import Storage from '../helpers/Storage'
import Field from '../components/Field'
import Alert from '../components/Alert'
import '../css/Machines.css'
import PurchasesScreen from './Purchases';

/**
 * PRODUCT REPORT CONSTS
 */
const EXPIRED = "EXPIRED"
const STUCK = "STUCK"
const DAMAGED = "DAMAGED"
const INCOMPLETE = "INCOMPLETE"
const EMPTY = "EMPTY"
const OTHER = "OTHER"

const PurchaseScreen = withRouter(({history}) => {
  const [error, setError] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const [resolution, setResolution] = useState(false)
  const [successfulChange, setSuccessfulChange] = useState(false)
  const formData = useRef(new FormData({}))
  const purchase = useRef(history.location.state.purchase)
  switch(purchase.current.status) {
    case 'PENDING':
      purchase.current.status = "Pendiente"
      break
    case 'IN_PROGRESS':
      purchase.current.status = "En progreso"
      break
    case 'RESOLVED':
      purchase.current.status = "Resuelto"
      break
    case 'REJECTED':
      purchase.current.status = "Rechazado"
      break
  }

  const report_types = [
    [STUCK, "Producto atorado"],
    [EMPTY, "Posición vacia"],
    [EXPIRED, "Producto caducado"],
    [DAMAGED, "Producto dañado"],
    [INCOMPLETE, "Producto incompleto"],
    [OTHER, "Otro"]
  ]

  const onData = (key, value) => {
  }

  const createReport = () => {
    setIsRequesting(true)
    history.push('/report', { purchase : purchase.current })
  }

  const closeSession = useCallback(() => {
    Storage.setCredentials({})
    history.push('/')
  }, [history])

  const emptyValidation = (data) => {
    return false
  }

  const getFormattedDate = (date) => {
    date = new Date(date);
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    let day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    let hour = date.getHours().toString();
    let minutes = date.getMinutes().toString();
    
    return month + '/' + day + '/' + year + ' ' + hour + ":" + minutes;
  }

  useEffect(() => {
    let data = Storage.getProfile()
    
  }, [purchase, closeSession])

  return (
    <div>
      <Header secondaryButton={'CANCEL'} />
      <div className="title">
        <h1>Detalle de compra</h1>
      </div>

      <div className='fields-container'>
        <form>
          <Field 
            name='purchase_date'
            label='Fecha de compra'
            initialValue={getFormattedDate(purchase.current.date)}
            onValidate={emptyValidation}
            disabled={true}
            onData={formData.current.onData()} />
          <Field 
            name='machine_name'
            label='Máquina'
            initialValue={purchase.current.machine_name}
            onValidate={emptyValidation}
            disabled={true}
            onData={formData.current.onData()} />
          <Field 
            name='id_session'
            label='Número de compra'
            initialValue={purchase.current.id_purchase}
            autoComplete='name'
            onValidate={emptyValidation}
            disabled={true}
            onData={formData.current.onData()} />
          <Field 
            name='payment_method'
            label='Forma de pago'
            initialValue={purchase.current.payment_type === "FUNDS" ? "Saldo" : "Tarjeta"}
            autoComplete='name'
            onValidate={emptyValidation}
            disabled={true}
            onData={formData.current.onData()} />
          { purchase.current.payment_type === "CARD" &&
            <Field 
              name='authorization_number'
              label='Autorización'
              initialValue={purchase.current.auth_number}
              autoComplete='name'
              onValidate={emptyValidation}
              disabled={true}
              onData={formData.current.onData()} />
          }
          <Field 
            name='product_name'
            label='Nombre de producto'
            initialValue={purchase.current.product_name}
            autoComplete='name'
            onValidate={emptyValidation}
            disabled={true}
            onData={formData.current.onData()} />
          <Field 
            name='product_price'
            label='Precio de producto'
            initialValue={purchase.current.product_price !== null ? "$"+purchase.current.product_price : "$0.00"}
            autoComplete='name'
            onValidate={emptyValidation}
            disabled={true}
            onData={formData.current.onData()} />
          <Field 
            name='purchase_reported'
            label='Reporte'
            initialValue={purchase.current.report === 1 ? "Reportado" : "Sin reporte"}
            onValidate={emptyValidation}
            disabled={true}
            onData={formData.current.onData()} />
          { purchase.current.report === 1 &&
            <Field 
              name='report_status'
              label='Estatus de reporte'
              initialValue={purchase.current.status}
              autoComplete='name'
              onValidate={emptyValidation}
              disabled={true}
              onData={formData.current.onData()} />
          }

        </form>
      </div>
      { purchase.current.status === "SUCCESS" && purchase.current.report === 0 && purchase.current.product_price !== null &&
        <div className='buttons'>
          <button className='primary' onClick={createReport} disabled={isRequesting}>Crear reporte</button>
        </div>
      }
      { successfulChange &&
        <Alert onClick={() => { setSuccessfulChange(false) }}>
          <span className='icon-ok-circled icon-size-large icon-color-green'></span>
          {successfulChange}
        </Alert>
      }
      { error &&
        <Alert onClick={() => { setSuccessfulChange(false) }}>
          <span className='error icon-size-large icon-color-green'></span>
          {error}
        </Alert>
      }
    </div>
  )
})

export default PurchaseScreen