import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Header from './Header'
import Request from '../helpers/Request'
import '../css/Reports.css'

const EXPIRED = "EXPIRED"
const STUCK = "STUCK"
const DAMAGED = "DAMAGED"
const INCOMPLETE = "INCOMPLETE"
const EMPTY = "EMPTY"
const OTHER = "OTHER"

const ReportsScreen = withRouter(({ history }) => {
  const [reports, setReports] = useState([])
  const [fetching, setFetching] = useState(false)

  const getReportType = (reportType) => {
    switch (reportType) {
      case EXPIRED:
        return "Producto caducado"
      case STUCK:
        return "Producto atorado"
      case DAMAGED:
        return "Producto dañado"
      case INCOMPLETE:
        return "Producto incompleto"
      case EMPTY:
        return "Posición vacia"
      case OTHER:
        return "Otro"
      default:
        return "Otro"
    }
  }

  useEffect(() => {
    setFetching(true)
    Request.get('/user/reports', null).then((data) => {
      setFetching(false)
      setReports(data)
    }).catch((error) => {
      setFetching(false)
    })
  }, [])

  return (
    <div>
      <Header secondaryButton={'CANCEL'} />
      <div className="title-list">
        <h1>Reportes</h1>
      </div>
      { fetching &&
        <div className="reports-search">
          <i className="icon-spin spin"></i>
          <div>Cargando...</div>
        </div>
      }
      <div className="reports-list">
        {!fetching && reports.length == 0 &&
          <div className="empty-state">No se han encontrado compras</div>
        }
        { reports && reports.map((report) => {
            return (
              <div key={report.id_report} className="report-card">
                { report.status === 'IN_PROGRESS' || report.status === 'PENDING' &&
                  <div className="report-status">
                    <i className="icon-spin spin orange icon-size-medium"></i>
                    <p className="orange">En proceso</p>
                  </div>
                }
                { report.status === 'RESOLVED' &&
                  <div className="report-status">
                    <i className="icon-ok-circled green icon-size-medium"></i>
                    <p className="green">Completado</p>
                  </div>
                }
                { report.status === 'REJECTED' &&
                  <div className="report-status">
                    <i className="icon-cancel-circled red icon-size-medium"></i>
                    <p className="red">Rechazado</p>
                  </div>
                }
                  <div className="report-data">
                    <div className="report-date">{ report.created }</div>
                    <div className="report-product">{ report.product_name } - ${ report.product_price } </div>
                    <div className="report-type">{ getReportType(report.case_type) }</div>
                  </div>
              </div>
            )
          }) }
      </div>
    </div>
  )
})

export default ReportsScreen