import React, { useEffect, useState, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import Request from '../helpers/Request'

const ActivateAccountScreen = withRouter(({ history }) => {
  const [message, setMessage] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)

  var match = window.location.search.match(/token=([0-9a-zA-Z]+)/)

  const getResponse = useCallback(() => {
    setIsRequesting(true)
    Request.get('/activate/' + match[1], null).then((data) => {
      setMessage("¡Tu cuenta ha sido activada exitosamente!")
    }).catch((error) => {
      setIsRequesting(false)
      setMessage("Ha ocurrido un error al activar tu cuenta")
    })
  }, [match[1]])

  useEffect(() => {
    getResponse()
  }, [getResponse])

  return (
    <div>
      <div className="title">
        <h1>Activación de cuenta</h1>
      </div>
      <div className='fields-container'>
        <h3>{message}</h3>
      </div>
      <div className='buttons'>
        <button className='primary' disabled={isRequesting} onClick={() => history.push('/')}>Aceptar</button>
      </div>
    </div>
  )
})

export default ActivateAccountScreen