import React, { useState, useEffect, useRef, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import Header from './Header';
import Select from '../components/Select';
import FormData from '../helpers/FormData'
import Request from '../helpers/Request'
import Storage from '../helpers/Storage'
import Field from '../components/Field'
import Alert from '../components/Alert'
import '../css/Machines.css'

/**
 * MACHINE REPORT CONSTS
 */
const OFFLINE = "OFFLINE"
const COIN = "COIN"
const DIRTY = "DIRTY"
const VANDALIZED = "VANDALIZED"


const ReportMachineScreen = withRouter(({history}) => {
  const [error, setError] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const [successfulChange, setSuccessfulChange] = useState(false)
  const formData = useRef(new FormData({}))
  const machine = useRef(history.location.state.machine)

  const report_types = [
    [OFFLINE, "Fuera de servicio"],
    [COIN, "Sin cambio"],
    [DIRTY, "Sucia"],
    [VANDALIZED, "Vandalizada"]
  ]

  const onData = (key, value) => {
    
  }

  const saveReport = () => {
    formData.current.data()["id_company"] = machine.current.id_company
    formData.current.data()["id_machine"] = machine.current.id_machine
    formData.current.data()["machine_name"] = machine.current.machine_name
    //if (formData.current.allValid()) {
      setIsRequesting(true)
      Request.post('/add_report', formData.current.data()).then((data) => {
        setIsRequesting(false)
        setSuccessfulChange("Se envió el reporte exitosamente")
      }).catch((error) => {
        setIsRequesting(false)
        setError(error.message)
      })
    //}
  }

  const closeSession = useCallback(() => {
    Storage.setCredentials({})
    history.push('/')
  }, [history])

  const emptyValidation = (data) => {
    return false
  }

  useEffect(() => {
    let data = Storage.getProfile()
  }, [closeSession])

  return (
    <div>
      <Header secondaryButton={'CANCEL'} />
      <div className="title">
        <h1>Nuevo reporte</h1>
      </div>

      <div className='fields-container'>
        <form>
          <Select
            name="report_type" 
            label="Estado de la máquina: " 
            options={report_types} 
            onData={formData.current.onData()} />
          <Field 
            name='description' 
            label='Descripción' 
            initialValue={null}
            autoComplete='email'
            autoCapitalize='off'
            onValidate={emptyValidation}
            onData={formData.current.onData()} />
        </form>
      </div>
      <div className='buttons'>
        <button className='primary' onClick={saveReport} disabled={isRequesting}>Guardar</button>
      </div>
      { successfulChange &&
        <Alert onClick={() => { history.go(-2) }}>
          <span className='icon-ok-circled icon-size-large icon-color-green'></span>
          {successfulChange}
        </Alert>
      }
      { error &&
        <Alert onClick={() => { setError(false) }}>
          <span className='error icon-size-large icon-color-green'></span>
          {error}
        </Alert>
      }
    </div>
  )
})

export default ReportMachineScreen