import React, { useRef } from 'react'

const Search = ({onSearch}) => {
  const tainted = useRef(false)
  const input = useRef()
  const value = useRef('')
  const changeTimer = useRef(null)

  const handleChange = () => {
    if (changeTimer.current) {
      clearTimeout(changeTimer.current)
      changeTimer.current = null
    }
    value.current = input.current.value
    if (onSearch && tainted.current) {
      onSearch(value.current)
      tainted.current = false
    }
  }

  const onChange = () => {
    if (changeTimer.current) clearTimeout(changeTimer.current)
    changeTimer.current = setTimeout(handleChange, 0)
    tainted.current = true
  }

  const clearValue = () => {
    if (input.current.value === '') return
    input.current.value = ''
    tainted.current = true
    handleChange()
  }

  return (
    <div className="search-area icon-search field-icon">
      <input 
        ref={input}
        type="text" 
        placeholder="Buscar por nombre" 
        onChange={onChange} 
        onBlur={handleChange} />
      <span className="icon-cancel-circled" onClick={() => clearValue()}></span>
    </div>
  )
}

export default Search