import React from 'react';
import { Router, Route, Switch } from "react-router-dom"
import { createBrowserHistory } from "history"
import SplashScreen from './modules/Splash'
import LoginScreen from './modules/Login'
import QuickLoginScreen from './modules/QuickLogin'
import RegisterScreen from './modules/Register'
import MachinesScreen from './modules/Machines'
import AddFundsScreen from './modules/AddFunds'
import PurchasesScreen from './modules/Purchases'
import PurchaseScreen from './modules/Purchase'
import PurchasesListenerScreen from './modules/PurchasesListener'
import ReportsScreen from './modules/Reports'
import AddCardScreen from './modules/AddCard'
import MachineScreen from './modules/Machine'
import ReportScreen from './modules/Report'
import ReportMachineScreen from './modules/ReportMachine'
import ProfileScreen from './modules/Profile'
import PrivacyPolicyScreen from './modules/PrivacyPolicy'
import TermsAndConditionsScreen from './modules/TermsAndConditions'
import ForgotPasswordScreen from './modules/ForgotPassword'
import PasswordRecoveryScreen from './modules/PasswordRecovery'
import SettingsScreen from './modules/Settings'
import ActivateAccountScreen from './modules/ActivateAccount'
import ConfirmEmailScreen from './modules/ConfirmEmail'
import './css/App.css';
import './css/vendicenter-cashless.css'

const App = () => {
  const history = createBrowserHistory()

  return (
    <Router history={history}>
      <div>
        <Switch>
          <Route exact path="/" component={SplashScreen} />
          <Route path="/login" component={LoginScreen} />
          <Route path="/quick-login" component={QuickLoginScreen} />
          <Route path="/register" component={RegisterScreen} />
          <Route path="/machines" component={MachinesScreen} />
          <Route path="/add-funds" component={AddFundsScreen} />
          <Route path="/purchases" component={PurchasesScreen} />
          <Route path="/purchases-listener" component={PurchasesListenerScreen} />
          <Route path="/purchase" component={PurchaseScreen} />
          <Route path="/reports" component={ReportsScreen} />
          <Route path="/add-card" component={AddCardScreen} />
          <Route path="/machine" component={MachineScreen} />
          <Route path="/report" component={ReportScreen} />
          <Route path="/report-machine" component={ReportMachineScreen} />
          <Route path="/profile" component={ProfileScreen} />
          <Route path="/privacy-policy" component={PrivacyPolicyScreen} />
          <Route path="/terms-and-conditions" component={TermsAndConditionsScreen} />
          <Route path="/recovery" component={PasswordRecoveryScreen} />
          <Route path="/forgot-password" component={ForgotPasswordScreen} />
          <Route path="/settings" component={SettingsScreen} />
          <Route path="/activate-account" component={ActivateAccountScreen} />
          <Route path="/confirm-email" component={ConfirmEmailScreen} />
        </Switch>
      </div>
    </Router>
  )
}

export default App