import React, { useRef, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Header from './Header'
import Select from '../components/Select'
import Radio from '../components/Radio'
import Request from '../helpers/Request'
import Modal from '../components/Modal'
import Alert from '../components/Alert'
import Timeout from '../components/Timeout'
import Storage from '../helpers/Storage'

const AddFundsScreen = withRouter(({ history }) => {
  var fundOptions = [
    [5000, "$ 50.00"],
    [10000, "$ 100.00"],
    [15000, "$ 150.00"],
    [20000, "$ 200.00"]
  ]
  var initialFund = 10000

  const [cards, setCards] = useState([])
  const [isRequesting, setRequesting] = useState(false)
  const [error, setError] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [funding, setFunding] = useState(false)
  const defaultMachine = Storage.get('default_machine')
  const funds = useRef(initialFund)
  const creditCard = useRef(null)
  const bynt_fingerprint = Storage.get("bynt_token")

  const onData = (key, value) => {
    if (key === 'funds') {
      funds.current = value
    } else if (key === 'credit-card') {
      creditCard.current = value
    }
  }

  const addFunds = () => {
    setConfirm(false)
    setFunding(true)
    var params = {
      id_payment: creditCard.current,
      amount: funds.current,
      bynt_fingerprint: bynt_fingerprint
    }
    Request.post('/add_funds', params).then((data) => {
      setFunding(false)
      setConfirm(false)
      setSuccessMessage([
        {text: '¡Recarga exitosa!', style: {textAlign: 'left'}},
        {text: `Num. Autorización:`, style: {textAlign: 'left'}},
        {text: data.authNumber, style: {textAlign: 'left', fontWeight: "bold", margin: "0px"}}
      ])
    }).catch((error) => {
      setFunding(false)
      setConfirm(false)
      if (error.code === "DECLINED_BY_BAYONET") {
        setError('No se ha podido realizar la recarga con esta tarjeta, prueba con otra o intenta de nuevo más tarde.')
      } else {
        setError('La recarga fallo, por favor revisa si el método de pago es correcto e intenta de nuevo.')
      }
    })
  }

  const deleteCard = () => {
    setDeleting(false)
    var params = {
      id_payment: creditCard.current
    }
    Request.post('/delete_card', params).then((data) => {
      setDeleting(false)
      setSuccessMessage([
        {text: '¡Tarjeta eliminada!', style: {textAlign: 'left'}}
      ])
    }).catch((error) => {
      //Check error status
      setDeleting(false)
      setError('No se pudo eliminar la tarjeta, intenta de nuevo.')
    })
  }

  const addCard = () => {
    history.push('/add-card')
  }

  const closeSession = () => {
    Storage.setCredentials({})
    history.push('/')
  }

  useEffect(() => {
    Request.get('/get_methods', null).then((data) => {
      var cards = data.map((card) => {
        return [card.id_card, card.maskedPAN]
      })
      setCards(cards)
    }).catch((error) => {
      //TODO: error
    })
  }, [])

  return (
    <div>
      <Header label="Agregar tarjeta" onClick={addCard} secondaryButton={'CANCEL'} />
      <div className="title">
        <h1>Agregar fondos</h1>
      </div>
      <div className="fields-container">
        <Select
          name="credit-card" 
          label="Selecciona método de pago" 
          options={cards}
          onData={onData} />
        { cards.length > 0 &&
          <div className='buttons'>
            <button className='danger' onClick={() => setDeleting(true)} disabled={isRequesting}>Eliminar tarjeta</button>
          </div>
        }
        <Radio 
          name="funds" 
          label="Selecciona la cantidad" 
          options={fundOptions} 
          initialValue={funds.current} 
          onData={onData} />
      </div>
      <div className='buttons'>
        <button className='primary' onClick={() => setConfirm(true)} disabled={isRequesting}>Pagar ahora</button>
      </div>
      { defaultMachine !== null &&          
      <div className='banner red'>
          <Timeout minutes={1} puase={funding} onTimeout={() => { closeSession() }}>Desconexión en</Timeout>
      </div>
      }
      { confirm &&
        <Modal>
          <div className="confirm-modal">
            <i className="icon-credit-card blue"></i>
            <div>¿Realizar la recarga de saldo?</div>
          </div>
          <div className='buttons'>
            <button className='secondary' onClick={() => setConfirm(false)} disabled={isRequesting}>Cancelar</button>
            <button className='primary' onClick={addFunds} disabled={isRequesting}>Aceptar</button>
          </div>
        </Modal>
      }
      { deleting &&
        <Modal>
          <div className="confirm-modal">
            <i className="icon-credit-card blue"></i>
            <div>¿Eliminar tarjeta?</div>
          </div>
          <div className='buttons'>
            <button className='secondary' onClick={() => setDeleting(false)} disabled={isRequesting}>Cancelar</button>
            <button className='primary' onClick={deleteCard} disabled={isRequesting}>Aceptar</button>
          </div>
        </Modal>
      }
      { funding &&
        <Modal>
          <div className="confirm-modal">
            <i className="icon-spin spin"></i>
            <div>Realizando recarga</div>
          </div>
        </Modal>
      }
      { error &&
        <Alert onClick={() => setError(false) }>
          <span className='icon-error icon-size-large icon-color-red'></span>
          {error}
        </Alert>
      }
      { successMessage &&
        <Alert onClick={() => { history.go(-1)}}>
          <span className='icon-credit-card icon-size-large icon-color-green'></span>
          <div style={{display: "inline", width: "100%"}}>
          {
            successMessage.map((line, i) => {
              let style = null
              if (typeof line.style !== 'undefined') {
                style = line.style
              }
              return (<p key={i} style={style}>{line.text}</p>)
            })
          }
          </div>
        </Alert>
      }
    </div>
  )
})

export default AddFundsScreen