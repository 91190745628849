import React, { useState, useEffect, useCallback, useRef } from 'react';
import { withRouter, Link } from 'react-router-dom'
import { usePosition } from '../helpers/UsePositionHook';
import Modal from '../components/Modal';
import Header from './Header';
import FundsFooter from './FundsFooter';
import Search from '../components/Search'
//import Slideshow from '../components/Slideshow'
import MachineImage from '../images/machine.png'
import Storage from '../helpers/Storage'
import Request from '../helpers/Request'
import '../css/Machines.css'

const MachinesScreen = withRouter(({ history }) => {
  const watch = true;
  const [favorites, setFavorites] = useState(JSON.parse(localStorage.getItem('favoriteMachines') || '{}'))
  const [machines, setMachines] = useState(null)
  const [searching, setSearching] = useState(false)
  const [noResults, setNoResults] = useState(false)
  const [slider, setSlider] = useState(false)
  const { latitude, longitude, accuracy, error, waiting } = usePosition(watch, {enableHighAccuracy: true})
  let defaultMachine = Storage.get('default_machine')
  const purchase_enabled = (Storage.get("purchase_enabled") === 'true');
  const autovend = (Storage.get("autovend") === 'true');
  const productControl = (Storage.get("product_control") === 'true');
  const timer = useRef(null)

  if (defaultMachine !== null) {
    let machineJson = JSON.parse(defaultMachine)
    history.push('/machine', { machine: machineJson })
  }

  const closeSession = useCallback(() => {
    Storage.setCredentials({})
    history.push('/')
  }, [history])
  
  const searchAgain = () => {
    setNoResults(false)
    nearestMachines()
  }

  const nearestMachines = useCallback(() => {
    setMachines(false)
    setSearching(true)
    var options = {
      lat: latitude,
      lon: longitude,
      rad: 1000 + accuracy // TODO: Reduce the range
    }
    if(productControl) {
      const pcmachines = JSON.parse(Storage.get("pcontrol_machines"))
      setMachines(Object.values(pcmachines))
      setSearching(false)
    } else {
      Request.get('/machines/nearest', options).then((data) => {
        setSearching(false)
        setMachines(data)
        console.log("Machines data: ", data)
      }).catch((error) => {
        if (error.code === 'AUTHORIZATION_ERROR') {
          closeSession()
        } else {
          setNoResults(true)
          setSearching(false)
          setMachines(false)
        }
      })
    }
  }, [latitude, longitude, accuracy, closeSession])

  useEffect(() => {
    if (timer.current !== null) clearInterval(timer.current)
    timer.current = setInterval(nearestMachines, 45000)
    return () => clearInterval(timer.current);
  }, [nearestMachines])

  const doSearch = (text) => {
    setMachines(false)
    if (text.length === 0) {
      nearestMachines()
    } else if (text.length >= 3) {
      setSearching(true)
      let options = {
        lat: latitude,
        lon: longitude,
        rad: accuracy ? 500000 + accuracy : undefined // TODO: Reduce the range
      }
      Request.get('/machines', { search_text: text, ...options }).then((data) => {
        setSearching(false)
        setMachines(data)
      }).catch((error) => {
        setSearching(false)
        setMachines([])
      })
    }
  }

  useEffect(() => {
    localStorage.setItem('favoriteMachines', JSON.stringify(favorites));
    if (autovend) {
      history.push('/purchases-listener')
    }
  }, [favorites])

  /**
   * Make Slider appears from the start
   */
  /* useEffect(() => {
    setSlider(true)
  }, [slider]) */

  useEffect(() => {
    if (latitude && longitude) {
      nearestMachines()
    }
  }, [latitude, longitude, nearestMachines])

  const toggleFavorite = (id_machine) => {
    setFavorites({ ...favorites, [`${id_machine}`]: !favorites[id_machine] })
  }

  const readableDistance = (distance) => {
    if (waiting) return '...'

    if (distance >= 1000) {
      return (Math.round(distance / 100) / 10) + ' km'
    } else {
      return Math.round(distance) + ' m'
    }
  }

  return (
    <div>
      <Header />
      <div className="title">
        <h1>Máquinas disponibles</h1>
      </div>
      <div className="fields-container">
        <Search onSearch={doSearch} />
      </div>
      <div className="machine-list">
        {waiting && !error && !searching &&
          <div className="machine-search">
            <i className="icon-spin spin"></i>
            <div>Obteniendo tu ubicación...</div>
          </div>
        }
        { searching &&
          <div className="machine-search">
            <i className="icon-spin spin"></i>
            <div>Buscando máquinas expendoras cercanas...</div>
          </div>
        }
        {error && !machines && !searching && latitude === undefined &&
          <div className="machines-error">
            <div className="machine-search">
              <i className="icon-attention orange"></i>
              <div className="machines-error-message">
                No se pudo obtener tu ubicación actual. Verifica que la aplicación tenga acceso a tu ubicación ó intenta escribir nombre de la máquina expendedora en el campo de búsqueda.
              </div>
            </div>
            <Link className='link' onClick={nearestMachines}>Volver a buscar</Link>
          </div>
        }
        { noResults &&
          <div className="machines-search-not-found">
            <div className="machine-search">
              <i className="icon-attention orange"></i>
              <div>No se encontraron máquinas expendedoras cercanas a tu ubicación.</div>
            </div>
            <Link className='link' onClick={searchAgain}>Volver a buscar</Link>
          </div>
        }
        {!searching && !waiting && machines && machines.length === 0 &&
          <div>
            <div className="machine-search">
              <i className="icon-attention orange"></i>
              <div>No se encontraron máquinas expendedoras cercanas a tu ubicación.</div>
            </div>
            <Link className='link' onClick={nearestMachines}>Volver a buscar</Link>
          </div>
        }
        {!waiting && machines && machines.map((machine) => {
          return (
            <div key={machine.id_machine + machine.id_company} className="machine-card">
              <img src={MachineImage} alt="Máquina Expendedora" onClick={() => history.push('/machine', { machine: machine })} />
              <div className="machine-data" onClick={() => history.push('/machine', { machine: machine })}>
                <div className="machine-name">{machine.machine_name}</div>
                <div className="machine-address">{machine.machine_address}</div>
                <div className="machine-distance">{readableDistance(machine.distance)}</div>
              </div>
              <span className={"machine-favorite " + (favorites[machine.id_machine] ? 'icon-heart' : 'icon-heart-empty')} onClick={() => toggleFavorite(machine.id_machine)}></span>
            </div>
          )
        })}
        {/* { slider &&
          <div>
            <Slideshow />
            <div className="buttons">
              <button className="secondary" onClick={() => setSlider(false)}>Salir</button>
            </div>
          </div>
        } */}
      </div>
      <FundsFooter purchase_enabled={purchase_enabled} />
    </div>
  )
})

export default MachinesScreen